import { Route, Routes } from "react-router-dom";
import Equipment from "../containers/About/Equipment";
import General from "../containers/About/General";
import Vision from "../containers/About/Vision";
// import CareerContainer from "../containers/Career";
import DetailCareer from "../containers/Career/Detail";
import AlbumDetail from "../containers/LiblaryImage/AlbumDetail";
import ListImage from "../containers/LiblaryImage/ListImage";
import Layout from "../Layout/Layout";
import LayoutManager from "../Layout/LayoutManager";
import About from "../Page/About";
// import Career from "../Page/Career";
import Contact from "../Page/Contact";
import Home from "../Page/Dashboard/Home";
import Users from "../Page/Dashboard/Users";
import DetailNews from "../Page/DetailNews";
import HomePage from "../Page/HomePage";
import LiblaryImage from "../Page/LibraryImage";
import Login from "../Page/Login";
import ProductManager from "../Page/ManagerPages/ProductManager";
import NewsPage from "../Page/NewsPage";
import NotFoundPage from "../Page/NotFoundPage";
import ProductDetails from "../Page/ProductDetails";
import Products from "../Page/Products";
import SystemManager from "../Page/SystemManager";
import History from "./../containers/About/History";
// import ManagerCareer from "../Page/Dashboard/Career";
// import CreateCareer from "../containers/Dashboard/Career/CreateCareer";
import FormContact from "../Page/Dashboard/FormContact";
import CandidateProfile from "../Page/Dashboard/CandidateProfile";
import CandidateProfileDetail from "../Page/Dashboard/CandidateProfileDetail";
import Advise from "../Page/Dashboard/Advise";
import ManagerImages from "../Page/Dashboard/LiblaryImages";
import LiblaryImagesDetail from "../Page/Dashboard/LiblaryImagesDetail";
import Editor from "../components/Editor";
import FormModal from "../containers/Career/FormModal";
import NewsManager from "../Page/ManagerPages/NewsManager";
import AccountInfoamation from "../Page/ManagerPages/AccountInfoamation";
import EditAccount from "../Page/ManagerPages/EditAccount";
import ChangePassword from "../Page/ManagerPages/ChangePassword";
import DeviceManager from "../Page/ManagerPages/DeviceManager";
import HistoryManager from "../Page/ManagerPages/HistoryManager";
// import EditCareer from "../containers/Dashboard/Career/EditCareer";
import { useAppSelector } from "../hooks/hook";
import { ROLE_ADMIN, ROLE_USER } from "../types";
import CreateNews from "../components/ManagerComponent/CreateNews";
import ViewsNew from "../components/ManagerComponent/ViewsNews";
import SearchAllPage from "../Page/SearchAllPage";
import ProjectPage from "../Page/Project";
import DetailProject from "../Page/Project/detail";
import ProjectManager from "../Page/ManagerPages/ProjectManager";
import ProducSlide from "../components/managerModal/ProducSlide";
import ProducSlideProject from "../components/managerModal/ProducSlideProject";
import CategoryModal from "../components/ManagerComponent/CategoryModal";
import UserService from "../services/UserService";

function RootRouter() {
  const user = useAppSelector((state) => state.userSlice);
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<HomePage />} />
        <Route path="sanpham" element={<Products />} />
        <Route path="chitietsanpham/:id" element={<ProductDetails />} />

        <Route path="tin-tuc" element={<NewsPage />} />
        <Route path="tin-tuc/:id" element={<DetailNews />} />

        <Route path="du-an" element={<ProjectPage />} />
        <Route path="du-an/:id" element={<DetailProject />} />

        {/* <Route path="tuyen-dung" element={<Career />}>
          <Route index element={<CareerContainer />} />
          <Route path=":id" element={<DetailCareer />} />
          <Route path="bieu-mau/:id" element={<FormModal />} />
        </Route> */}

        <Route path="lien-he" element={<Contact />} />

        <Route path="album" element={<LiblaryImage />}>
          <Route index element={<ListImage />} />
          <Route path=":id" element={<AlbumDetail />} />
        </Route>

        <Route path="gioi-thieu" element={<About />}>
          <Route index element={<General />} />
          <Route path="lich-su" element={<History />} />
          <Route path="gia-tri-cot-loi" element={<Vision />} />
          <Route path="trang-thiet-bi" element={<Equipment />} />
        </Route>

        <Route path="search" element={<SearchAllPage />} />

        <Route path="gioi-thieu/he-thong-quan-ly" element={<SystemManager />} />
      </Route>
      <Route path="/quanly" element={<LayoutManager />}>
        {/* <Route path="/quanly" element={ <LayoutManager />}> */}
        <Route path="taikhoan" element={<Users />} />
        <Route path="trangchu" element={<Home />} />
        <Route path="category/add" element={<CategoryModal />} />
        {/* <Route path="tuyendung" element={<ManagerCareer />} />
        <Route path="tuyendung/create" element={<CreateCareer />} />

        <Route path="tuyendung/edit/:idEdit" element={<EditCareer />} />
        <Route path="tuyendung/view/:idView" element={<EditCareer />} /> */}
        <Route path="sanpham" element={<ProductManager />} />
        <Route path="sanpham/add" element={<ProducSlide />} />
        <Route path="sanpham/edit/:id" element={<ProducSlide />} />
        <Route path="du_an" element={<ProjectManager />} />
        <Route path="du_an/edit/:id" element={<ProducSlideProject />} />
        <Route path="du_an/add" element={<ProducSlideProject />} />
        <Route path="lienhe" element={<FormContact />} />
        {/* <Route path="hosoungvien" element={<CandidateProfile />} />
        <Route path="hosoungvien/:id" element={<CandidateProfileDetail />} /> */}
        <Route path="tuvan" element={<Advise />} />

        <Route path="thuvien" element={<ManagerImages />} />
        <Route path="thuvien/:id" element={<LiblaryImagesDetail />} />

        <Route path="tintuc" element={<NewsManager />} />
        <Route path="tintuc/create" element={<CreateNews />} />
        <Route path="tintuc/view/:id" element={<ViewsNew />} />
        <Route path="tintuc/edit/:id" element={<CreateNews />} />
        <Route path="thongtintaikhoan" element={<AccountInfoamation />} />
        <Route path="thongtintaikhoan/edit" element={<EditAccount />} />
        {/* <Route
          path="password"
          element={

            <ChangePassword />

          }
        /> */}
        <Route path="device" element={<DeviceManager />} />
        <Route path="history" element={<HistoryManager />} />
      </Route>

      <Route path="/dang-nhap" element={<Login />} />
      {/* <Route path="/editor" element={<Editor />} /> */}

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default RootRouter;
