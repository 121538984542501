import clsx from "clsx";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import useInView from "../../hooks/useInView";
import { hostBE } from "../../types/host";
import PopUpForm  from "../Contact/PopupForm";
import { useAppDispatch } from "../../hooks/hook";
import { showModal } from "../../reducers/modal";


export default function ContactBottom() {

    const navigate = useNavigate();
    const [t] = useTranslation();
    const {ref, isInView} = useInView()
    const dispatch = useAppDispatch();

    const showModalContact = () => {
        dispatch(showModal(<PopUpForm />))
      };

    return (
        <div className="lssm:h-auto sc<992:h-[579px]  max-w-full  lssm:px-[24px] md:pl-[80px]  xl:pl-[50px]  w-1920:pl-[162px]   bg-[#EBEFF4] ] py-[92px] relative" ref={ref}>
                <div className="flex">
                    <div className="w-[40%] sc991:w-[100%] h-[380px] sc991:h-auto flex flex-col justify-between text-[#144E8C]">
                            <h2 className={clsx(" text-[32px] uppercase leading-[40px] sc991:text-px20 font-bold", {"animate__animated animate__fadeInDown":isInView})}>{t("home.topic.topic5")}</h2>
                            <div className="sc991:flex  justify-between  sc991:mt-[30px] sc>768:mb-[30px]">
                                <div className="w-[330px] h-[2px] bg-primary" />
                                                  
                            </div>
                    <div className={clsx("sc<992:w-[500px] sc<992:h-[478px] xl:w-[600px] w-1920:w-[973px]  2xl:w-[800px] sc991:w-full sc<992:overflow-hidden   sc991:h-auto   flex justify-end absolute bottom-[50px] right-[38px] bg-[#EAEBEF] rounded-tl-[12px] sc991:static", {"animate__animated animate__fadeInUp":isInView})}>
                        <img src={`${hostBE}/fe/lien_he_1.png`} alt="contacer" className="h-full max-w-full sc991:w-full object-cover rounded-[12px]" />
                    </div>
                            <p className={clsx("lssm:text-px14 md:text-px16 text-justify sc991:mt-[30px] font-normal text-text-primary", {"animate__animated animate__fadeInLeftBig":isInView})}>{t("home.description_topic5")}</p>
                           <div className={clsx("sc991:flex justify-between sc>768:justify-center w-full sc991:mt-[30px]", {"animate__animated animate__fadeInTopRight":isInView})}>
                             <Button onClick={showModalContact} color="primary" className="py-[18px] px-[42px] sc>768:text-px14 font-medium max-w-fit">{t("button.contact")}</Button>
                           </div>
                    </div>
                </div>

        </div>
    )
    
}