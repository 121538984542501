import { useTranslation } from "react-i18next"
import Button from "../../components/Button";
import Input from "../../components/CareerInput";
import { useFormik } from "formik";
import * as Yup from "yup"
import contactService from "../../services/contact";
import { useAppDispatch } from "../../hooks/hook";
import { pushPopup } from "../../reducers/popupSlice";
export default function PopUpForm() {
    const [t] = useTranslation();
    const dispatch = useAppDispatch();

    const formik = useFormik({
        initialValues: {
                fullname: '',
                phone: "",
                email: "",
                note: "",
        },
        validationSchema: Yup.object({
                fullname: Yup.string().required(t("validate.error.required")).min(5, t("validate.error.min", {name: t("contact.form.name"), min: 5})),
                phone: Yup.string().required(t("validate.error.required")).matches(/^[0-9]{10}$/, t("validate.error.pattern", {name: t("contact.form.phone")})),
                email: Yup.string().required(t("validate.error.required")).matches(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, t("validate.error.pattern", {name: t("career.form.email")})),
                note: Yup.string().required(t("validate.error.required")).min(5, t("validate.error.min", {name: t("contact.form.help"), min: 5})),
        }),
        onSubmit: (values)=> {
                contactService.postContact(values).then(()=> {
                        dispatch(pushPopup({
                                type: "SUCCESS",                         
                                message: t("contact.form.postSuccess")
                        }))

                        resetForm()
                }).catch(()=> {
                        dispatch(pushPopup({
                                type: "ERROR",
                                message: t("contact.form.postFail")
                        }))
                })
        }
})

                const resetForm = () => {
                        formik.resetForm();
                }


    return (
        <div className="w-max lssm:mt-[30px] lg:mt-0">
        <form onSubmit={formik.handleSubmit} className="border lssm:w-[90vw] lg:w-[608px] bg-white text-darkBlue  shadow rounded-[10px] lssm:p-[24px]  lg:p-[66px] flex flex-col justify-center items-center">
            <h1 className="text-center xl:text-[32px] md:text-[25px] lssm:text-[20px] text-[#f6aa1c] not-italic tracking-[.03em] font-['sans-serif'] text-normal1 lssm:px-[40px] lg:px-0  font-bold lg:text-header2">ĐĂNG KÍ NHẬN TƯ VẤN MIỄN PHÍ NGAY</h1>
            <p className="text-black text-center lssm:text-wap-normal lssm:px-[40px] lg:px-0 lg:text-normal1 mt-2">Thông tin khách hàng sẽ được bảo mật tuyệt đối.</p>
            <div className="mt-8 w-full">
                <div className="mb-3">
                        <input
                        type="text"
                        name="fullname"
                        placeholder="Họ và tên"
                        value={formik.values.fullname}
                        onChange={formik.handleChange}
                        className="w-full h-[40px] border-b border-complementary-000 outline-none"
                        />
                        <span className="text-[14px] text-red-600 mt-1">
                        {formik.errors.fullname}
                        </span>
                </div>

                <div className="mt-[16px] mb-3">
                        <input
                        type="text"
                        name="phone"
                        placeholder="Số điện thoại"
                        value={formik.values.phone} 
                        onChange={formik.handleChange}
                        className="w-full h-[40px] border-b border-complementary-000 outline-none"
                        />
                        <span className="text-[14px] text-red-600 mt-1">
                        {formik.errors.phone}
                        </span>
                </div>

                <div className="mt-[16px] mb-3">
                        <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        className="w-full h-[40px] border-b border-complementary-000 outline-none"
                        />
                        <span className="text-[14px] text-red-600 mt-1">
                        {formik.errors.email}
                        </span>
                </div>

                <div className="mt-[16px] mb-3">
                        <input
                        type="text"
                        name="note"
                        placeholder="Nội dung cần được tư vấn"
                        value={formik.values.note}
                        onChange={formik.handleChange}
                        className="w-full h-[40px] border-b border-complementary-000 outline-none"
                        />
                        <span className="text-[14px] text-red-600 mt-1">
                        {formik.errors.note}
                        </span>
                </div>
            </div>
            <div className="mt-[16px]">
                <button className="btn-popup shadow-md w-[134px] h-[24px] rounded lg:w-[210px] lg:h-[40px] lg:rounded-[10px] text-[12px] lg:text-[18px] font-bold text-white bg-[#f6aa1c] after:top-1 after:left-1 lg:after:top-[7px] lg:after:left-[7px]" type="submit">
                {t("contact.form.btn_submit")}
                </button>
            </div>
        </form>
    </div>
    )

}