type Props = {
  width: number | string;
  height: number | string;
};

export default function Map({ width, height }: Props) {
  return (
    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3677.6542913055437!2d104.9815171!3d22.8152704!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x36cc79ac6ddf04a9%3A0x77885eaed8466042!2zMTgzIE5ndXnhu4VuIFRyw6NpLCBQLiBOZ3V54buFbiBUcsOjaSwgSMOgIEdpYW5n!5e0!3m2!1svi!2s!4v1679374199600!5m2!1svi!2s"
    width={width}
    height={height}
    ></iframe>
  );
}
