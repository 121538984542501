import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import convertToHtml from "../../common/convertoHtml";
import getDate from "../../common/getDate";
import Loadding from "../../components/Loadding/Loadding";
import { useAppSelector } from "../../hooks/hook";
import newService from "../../services/newService";
import projectServiece from "../../services/project";
import { Project } from "../../types";

export default function ProjectDetail() {
  const [project, setProject] = useState<Project>();
  const translate = useAppSelector((state) => state.translateSlice);
  const [isLoading, setIsLoading] = useState(false);
  const param = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    projectServiece.getById(parseInt(param.id + "")).then((data) => {
      setProject(data);
      setIsLoading(false)
    }).catch((error) => {

      setIsLoading(false)
      const {status} = error.response;
      if(status === 404) {
        navigate("/du-an");
      }

    });
  }, [param.id]);

  return !isLoading ? (
    <div className="lssm:mt-[24px] md:mt-[99px] md:pr-[66px] mb-[120px] ">
      <div className="mb-[53px]">
        <h3 className="lssm:text-px20 md:text-px24 xl:text-[32px] font-bold text-text-primary">
          {translate.isEnglish ? project?.titleVi : project?.titleEn}
        </h3>
      </div>

      <div className="mb-[60px]">
        <p className="lssm:text-px16   font-bold">
          {translate.isEnglish
            ? project?.descriptionVi
            : project?.descriptionEn}
        </p>
      </div>
      {project ? (
        <div
          className="lssm:text-px14 md:text-px16 font-normal"
          dangerouslySetInnerHTML={{
            __html: translate.isEnglish
              ? convertToHtml(project?.contentVi + "")
              : convertToHtml(project?.contentEn + ""),
          }}
        ></div>
      ) : (
        <></>
      )}
    </div>
  ) : (
    <div>
      <Loadding />
    </div>
  );
}
