import clsx from "clsx";
import { t } from "i18next";
import { ChangeEvent, Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import resizeFile from "../../common/reSize";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { getCategory } from "../../reducers/categorySlice";
import { hideModal } from "../../reducers/modal";
import { pushPopup } from "../../reducers/popupSlice";
import { editProduct } from "../../reducers/products";
import producService from "../../services/products";
import uploadService from "../../services/uploadImage";
import { productimageType, typeProductEdit } from "../../typeProps/Productype";
import { hostBE } from "../../types/host";
import Editor from "../Editor";
import { LableContent } from "./LableContent";
type ProductsProps = {
  handleCurrenPage?: () => void;
};

function ProducSlide({ handleCurrenPage }: ProductsProps) {
  const param = useParams();
  const dispatch = useAppDispatch();
  const navigator = useNavigate();
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const scroToTop = useRef<HTMLDivElement>(null);
  const { isEnglish } = useAppSelector((state) => state.translateSlice);
  const [isDisable, setDisable] = useState<boolean>(false);
  const [files, setFiles] = useState<any[] | []>([]);
  const [preview, setPreview] = useState<any[]>([]);
  const [listPreviewImages, setListPreviewImages] = useState<
    productimageType[]
  >([]);
  const [listItemDelete, setListItemDelete] = useState<any[]>([]);
  const [contentvi, setContentVi] = useState<any>("");
  const [contentEn, setContentEn] = useState<any>("");
  const refNewVi = useRef<any>();
  const refNewEn = useRef<any>();
  const { categoryList } = useAppSelector((state) => state.categorySlice);
  const listImageFiles = useRef<
    {
      id: number;
      file: File | string;
    }[]
  >([]);
  const listImageFilesEn = useRef<
    {
      id: number;
      file: File | string;
    }[]
  >([]);

  const [producInput, setProductInput] = useState<typeProductEdit>({
    categoryId: "",
    avatarUrl: "",
    avatarPath: "",
    descriptionEn: "",
    descriptionVi: "",
    titleEn: "",
    titleVi: "",
    priority: false,
    specificationList: [
      {
        nameEn: "",
        nameVi: "",
        specificationDetailList: [
          {
            propertiesEn: "",
            propertiesVi: "",
            valueVi: "",
            valueEn: "",
          },
        ],
      },
    ],
  });
  const slideToTop = async () => {
    window.scrollTo(0, 0);
  };

  const handleSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setProductInput({
      ...producInput,
      [name]: value,
    });
  };
  const handleInput = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const name = event.target.name;
    const value = event.target.value;
    setProductInput({
      ...producInput,
      [name]: value,
    });
  };
  const handleChoseFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files;
    if (file && files.length < 4 && preview.length < 4) {
      const FileSize = file[0].size;
      if (FileSize > 26675200) {
        dispatch(
          pushPopup({
            type: "WARNING",
            message: isEnglish
              ? "Tệp ảnh không quá 25Mb"
              : "Image file no more than 25Mb",
          })
        );
      } else {
        const newFile = await resizeFile(file[0], { width: 870, height: 580 });
        setFiles([...files, newFile]);
        const namePreview = URL.createObjectURL(newFile);
        setPreview([...preview, namePreview]);
      }
    }
    event.target.value = "";
  };

  const handleDeletePreview = (indexDelete: number) => {
    if (listPreviewImages.length === 0) {
      const newFiles = files.filter((item, index) => {
        return item != files[indexDelete];
      });
      setFiles([...newFiles]);
    } else {
      const checkSome = listPreviewImages.some((item) => {
        if (item?.imageUrl === preview[indexDelete]) {
          return true;
        }
        return false;
      });
      if (checkSome) {
        const newPreview: string[] = preview.filter((item, index) => {
          return item != preview[indexDelete];
        });
        setPreview([...newPreview]);
        return;
      } else {
        const checkDuplicate = listPreviewImages.filter((item) => {
          return preview.includes(item.imageUrl);
        });
        const checkUndefinde = checkDuplicate.every((item) => {
          if (item) {
            return true;
          }
          return false;
        });

        if (checkUndefinde && checkDuplicate.length > 0) {
          switch (checkDuplicate.length) {
            case 1:
              const newFiles = files.filter((item, index) => {
                return item != files[indexDelete - 1];
              });
              setFiles([...newFiles]);
              break;
            case 2:
              const newFiles1 = files.filter((item, index) => {
                return item != files[indexDelete - 2];
              });
              setFiles([...newFiles1]);
              break;
            case 3:
              setFiles([]);
              break;
            case 4:
              const newPreview: string[] = preview.filter((item, index) => {
                return item != preview[indexDelete];
              });
              setPreview([...newPreview]);
              break;
            default:
              break;
          }
        } else {
          const newFiles = files.filter((item, index) => {
            return item != files[indexDelete];
          });
          setFiles([...newFiles]);
        }
      }
    }
    const newPreview: string[] = preview.filter((item, index) => {
      return item != preview[indexDelete];
    });
    setPreview([...newPreview]);
  };

  const handleChangeTitle = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const targetEvent = event.target;
    const nameEvent = targetEvent.name;
    const valueEvent = targetEvent.value;
    const newSpecifi = producInput.specificationList.map((item, index2) => {
      if (index2 == index) {
        return {
          ...item,
          [nameEvent]: valueEvent,
        };
      } else {
        return item;
      }
    });
    setProductInput({
      ...producInput,
      specificationList: newSpecifi,
    });
  };
  const handleChangeTd = (
    event: ChangeEvent<HTMLInputElement>,
    index: number,
    index2: number
  ) => {
    const targetEvent = event.target;
    const nameEvent = targetEvent.name;
    const ValueEvent = targetEvent.value;
    const newValue = producInput.specificationList[
      index
    ].specificationDetailList.map((item, indexItem) => {
      if (index2 == indexItem) {
        return {
          ...item,
          [nameEvent]: ValueEvent,
        };
      } else {
        return item;
      }
    });
    const newSpecifi = producInput.specificationList.map(
      (itemSpeci, indexSpe) => {
        if (indexSpe == index) {
          return {
            ...itemSpeci,
            specificationDetailList: newValue,
          };
        } else {
          return itemSpeci;
        }
      }
    );
    // sửa code
    setProductInput({
      ...producInput,
      specificationList: newSpecifi,
    });
  };
  const handleChangeTdMap = (
    event: ChangeEvent<HTMLInputElement>,
    index: number,
    indexPro: number
  ) => {
    const targetEvent = event.target;
    const nameEvent = targetEvent.name;
    const valueEvent = targetEvent.value;
    const specitifiProperties =
      producInput.specificationList[index].specificationDetailList[indexPro];
    if (specitifiProperties) {
      const newValue = producInput.specificationList[
        index
      ].specificationDetailList.map((item, indexItem) => {
        if (indexPro == indexItem) {
          return {
            ...item,
            [nameEvent]: valueEvent,
          };
        } else {
          return item;
        }
      });
      const newSpecifi = producInput.specificationList.map(
        (itemSpeci, indexSpe) => {
          if (indexSpe == index) {
            return {
              ...itemSpeci,
              specificationDetailList: newValue,
            };
          } else {
            return itemSpeci;
          }
        }
      );
      setProductInput({
        ...producInput,
        specificationList: newSpecifi,
      });
    } else {
      const ListProperties =
        producInput.specificationList[index].specificationDetailList;
      const newObject = {
        propertiesEn: "",
        propertiesVi: "",
        valueVi: "",
        valueEn: "",
      };
      const ObjecPush = { ...newObject, [nameEvent]: valueEvent };
      ListProperties.push(ObjecPush);
      const newSpecifi = producInput.specificationList.map(
        (itemSpeci, indexSpe) => {
          if (indexSpe == index) {
            return {
              ...itemSpeci,
              specificationDetailList: ListProperties,
            };
          } else {
            return itemSpeci;
          }
        }
      );
      setProductInput({
        ...producInput,
        specificationList: newSpecifi,
      });
    }
  };
  const handleAddTable = () => {
    const newTable = {
      nameEn: "",
      nameVi: "",
      specificationDetailList: [
        {
          propertiesEn: "",
          propertiesVi: "",
          valueVi: "",
          valueEn: "",
        },
      ],
    };
    const lengSpeci = producInput.specificationList.every((item, index) => {
      if (item.specificationDetailList.length < 2) {
        return false;
      } else {
        return true;
      }
    });
    const checkTitle = producInput.specificationList.map((item, index) => {
      if (item.nameVi == "") {
        return item;
      }
    });

    const checkEmpty = producInput.specificationList.map((item, index) => {
      const list = item.specificationDetailList.some((item2, index2) => {
        if (item2.propertiesVi == "" || item2.valueVi == "") {
          return true;
        } else {
          return false;
        }
      });
      if (list) {
        return item;
      }
    });
    const checkUndefinde = checkEmpty.every((item) => {
      if (item === undefined) {
        return true;
      } else {
        return false;
      }
    });

    const checkTitleUnEpty = checkTitle.every((item) => {
      if (item === undefined) {
        return true;
      } else {
        return false;
      }
    });
    if (
      producInput.specificationList.length < 5 &&
      lengSpeci &&
      checkUndefinde &&
      checkTitleUnEpty
    ) {
      setProductInput({
        ...producInput,
        specificationList: [...producInput.specificationList, newTable],
      });
    }
  };

  const handleDeleteTable = () => {
    const SpeciVariable: any = [...producInput.specificationList];
    const indexDelete: number = producInput.specificationList.length;
    if (indexDelete && producInput.specificationList[indexDelete - 1]?.id) {
      const newListDelete = listItemDelete;
      newListDelete.push({
        id: producInput.specificationList[indexDelete - 1].id,
      });
      setListItemDelete([...newListDelete]);
    }
    SpeciVariable.pop();
    setProductInput({
      ...producInput,
      specificationList: [...SpeciVariable],
    });
  };

  const mapTr = (
    list: {
      propertiesEn: string;
      propertiesVi: string;
      valueVi: string;
      valueEn: string;
    }[],
    index: number,
    isVi: boolean
  ) => {
    let text: number[] = [];
    for (let i = list.length; i < 7; i++) {
      text.push(i);
    }
    const jsxTR = text.map((item) => {
      return (
        <tr key={item}>
          <td className="border border-border-gray w-2/5">
            <input
              name={isVi ? "propertiesVi" : "propertiesEn"}
              onChange={
                producInput.specificationList[index].specificationDetailList
                  .length == item
                  ? (event) => handleChangeTdMap(event, index, item)
                  : (event: ChangeEvent<HTMLInputElement>) => {
                      event.target.value = "";
                    }
              }
              type="text"
              placeholder="Name ..."
              className="w-full p-3 focus:outline-none"
            />
          </td>
          <td className="border border-border-gray w-3/5 ">
            <input
              name={isVi ? "valueVi" : "valueEn"}
              onChange={
                producInput.specificationList[index].specificationDetailList
                  .length == item
                  ? (event) => handleChangeTdMap(event, index, item)
                  : (event: ChangeEvent<HTMLInputElement>) => {
                      event.target.value = "";
                    }
              }
              type="text"
              placeholder="Content ..."
              className="w-full p-3 focus:outline-none "
            />
          </td>
        </tr>
      );
    });
    return jsxTR;
  };

  const hiddenformAdd = () => {
    if (handleCurrenPage) {
      handleCurrenPage();
    }
  };

  const handleSubmit = async () => {
    const output = await refNewVi.current?.save();
    const outPutEn = await refNewEn.current?.save();
    const formProductSend: any = { ...producInput };
    const checkTitleSpa = producInput.specificationList.some((item, index) => {
      if (item.nameEn === "" || item.nameVi === "") {
        return true;
      } else {
        return false;
      }
    });
    const newSpecification = producInput.specificationList.map(
      (item, index) => {
        const bbb = item.specificationDetailList.filter((item2, index2) => {
          if (
            item2.propertiesEn != "" ||
            item2.propertiesVi != "" ||
            item2.valueEn != "" ||
            item2.valueVi != "" ||
            item2.id
          ) {
            return item2;
          }
        });
        item.specificationDetailList = bbb;
        return item;
      }
    );
    const checkSpecDetail = newSpecification.some((item, index) => {
      const detailCheck = item.specificationDetailList.some(
        (itemDetail, indexDetail) => {
          if (
            itemDetail.propertiesEn === "" ||
            itemDetail.propertiesVi === "" ||
            itemDetail.valueEn === "" ||
            itemDetail.valueVi === ""
          ) {
            return true;
          } else {
            return false;
          }
        }
      );
      if (detailCheck) {
        return true;
      } else {
        return false;
      }
    });
    formProductSend.specificationList = newSpecification;

    if (
      producInput.titleEn == "" ||
      producInput.titleVi == "" ||
      producInput.descriptionEn == "" ||
      producInput.descriptionVi == "" ||
      output.blocks.length === 0 ||
      outPutEn.blocks.length === 0 ||
      checkTitleSpa ||
      checkSpecDetail
    ) {
      dispatch(
        pushPopup({
          message: isEnglish
            ? "Chưa nhập đầy đủ thông tin các trường"
            : "Full information not enter",
          type: "WARNING",
        })
      );
    } else if (categoryList.length === 0) {
      dispatch(
        pushPopup({
          message: isEnglish
            ? "Chưa có danh mục dịch vụ không thể thêm"
            : "No product category can't be added",
          type: "WARNING",
        })
      );
    } else {
      if (listImageFiles.current.length > 0) {
        const form_data = new FormData();
        const listfile = listImageFiles.current.map((item) => item.file);

        listfile.forEach((file) => {
          form_data.append("file", file);
        });
        const data = await uploadService.upload(form_data);
        const listNewImage = listImageFiles.current;
        data.list.forEach((item, index) => {
          listNewImage[index].file = item.image_url;
        });
        listImageFiles.current = [...listNewImage];

        listImageFiles.current.forEach((item) => {
          if (output?.blocks[item.id].type === "image") {
            output.blocks[item.id].data.file.url = item.file;
          }
        });
      }
      const content = JSON.stringify(output);
      setContentVi(content);
      if (listImageFilesEn.current.length > 0) {
        const form_data = new FormData();
        const listfileEn = listImageFilesEn.current.map((item) => item.file);
        listfileEn.forEach((file) => {
          form_data.append("file", file);
        });

        const data = await uploadService.upload(form_data);
        const listNewImage = listImageFilesEn.current;
        data.list.forEach((item, index) => {
          listNewImage[index].file = item.image_url;
        });
        listImageFilesEn.current = [...listNewImage];
        listImageFilesEn.current.forEach((item) => {
          if (outPutEn?.blocks[item.id].type === "image") {
            outPutEn.blocks[item.id].data.file.url = item.file;
          }
        });
      }
      const contentEn = JSON.stringify(outPutEn);
      setContentEn(contentEn);
      if (param.id) {
        if (preview.length > 0) {
          let listUlrImages: any[] = [];
          if (files.length > 0) {
            const data = new FormData();
            for (let i = 0; i < files.length; i++) {
              data.append("file", files[i]);
            }
            const { list } = await uploadService.upload(data);
            if (list) {
              const newListImage = list.map((item, index) => {
                return {
                  imageUrl: item.image_url,
                  imagePath: item.image_path,
                };
              });
              listUlrImages = newListImage;
            } else {
              dispatch(
                pushPopup({
                  type: "WARNING",
                  message: isEnglish
                    ? "Không tải được ảnh"
                    : "Not image upload",
                })
              );
              return;
            }
          }
          const imagesClone: any[] = [...listPreviewImages];
          const newImages = imagesClone.map((item, index) => {
            const checkPreview = preview.some((itemPreview, indexPreview) => {
              if (itemPreview === item.imageUrl) {
                return true;
              } else {
                return false;
              }
            });
            if (!checkPreview) {
              item = { id: item.id };
            }
            return item;
          });
          formProductSend.id = param.id;
          const urlImage = listUlrImages.concat(newImages);
          formProductSend.productImageList = urlImage;
          formProductSend.specificationList =
            producInput.specificationList.concat(listItemDelete);
          formProductSend.contentVi = content;
          formProductSend.contentEn = contentEn;
          dispatch(editProduct(formProductSend));
          navigator("/quanly/sanpham");
        } else {
          dispatch(
            pushPopup({
              type: "WARNING",
              message: isEnglish
                ? "Bạn chưa chọn ảnh thay thế."
                : "You have not selected a replacement image.",
            })
          );
        }
      } else {
        if (files.length > 0) {
          const data = new FormData();
          for (let i = 0; i < files.length; i++) {
            data.append("file", files[i]);
          }

          const { list } = await uploadService.upload(data);
          const newListImage = list.map((item, index) => {
            return {
              imageUrl: item.image_url,
              imagePath: item.image_path,
            };
          });
          formProductSend.productImageList = newListImage;
          formProductSend.avatarUrl = list[0].image_url;
          formProductSend.contentVi = content;
          formProductSend.categoryId =
            producInput.categoryId !== ""
              ? producInput.categoryId
              : categoryList[0].id;

          formProductSend.contentEn = contentEn;
          setDisable(true);
          const result = await producService.post(formProductSend);
          if (result) {
            hiddenformAdd();
            dispatch(
              pushPopup({
                type: "SUCCESS",
                message: isEnglish
                  ? "Thêm dịch vụ thành công."
                  : "Successfully added products.",
              })
            );
            navigator("/quanly/sanpham");
          } else {
            dispatch(
              pushPopup({
                type: "WARNING",
                message: isEnglish
                  ? "Không thêm được dịch vụ."
                  : "Can't add products.",
              })
            );
            setDisable(false);
            dispatch(hideModal());
          }
        } else {
          dispatch(
            pushPopup({
              type: "WARNING",
              message: isEnglish
                ? "Bạn chưa chọn ảnh."
                : "You have not selected a photo.",
            })
          );
        }
      }
    }
  };

  useEffect(() => {
    if (refNewVi.current && contentvi) {
      refNewVi.current.render(JSON.parse(contentvi));
    }
  }, [refNewVi.current, contentvi]);
  useEffect(() => {
    if (refNewEn.current) {
      refNewEn.current.isReady.then(() => {
        refNewEn.current.render(JSON.parse(contentEn));
      });
    }
  }, [refNewEn]);

  useEffect(() => {
    return () => {
      preview.length > 0 &&
        preview.forEach((item: string, index: number) => {
          URL.revokeObjectURL(item);
        });
    };
  }, []);
  useEffect(() => {
    dispatch(getCategory());
  }, []);

  useEffect(() => {
    const callDetail = async () => {
      if (param.id) {
        const editProductDetail = await producService.getProducDetail(
          parseInt(param.id)
        );
        setContentVi(editProductDetail.contentVi);
        setContentEn(editProductDetail.contentEn);
        setProductInput({
          ...producInput,
          categoryId: editProductDetail.categoryId,
          titleEn: editProductDetail.titleEn,
          titleVi: editProductDetail.titleVi,
          avatarUrl: editProductDetail.avatarUrl,
          avatarPath: editProductDetail.avatarPath,
          descriptionEn: editProductDetail.descriptionEn,
          descriptionVi: editProductDetail.descriptionVi,
          priority: editProductDetail.priority,
          specificationList: editProductDetail.specificationList,
        });
        const pre = editProductDetail.productImageList.map((item) => {
          return item.imageUrl;
        });
        setPreview(pre);
        setListPreviewImages(editProductDetail.productImageList);
      }
    };
    callDetail();
  }, [param.id]);

  return (
    <div
      ref={scroToTop}
      className="w-full  w-1920:my-[120px] mt-0 h-auto bg-white"
    >
      <Swiper
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        simulateTouch={false}
        onSwiper={(swiper: any) => {
          setTimeout(() => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
            swiper.navigation.destroy();
            swiper.navigation.init();
            swiper.navigation.update();
          });
        }}
        modules={[Navigation]}
      >
        <SwiperSlide className="w-full sm-480:ml-[-2px] ml-0 ">
          <div className="w-full flex justify-end h-auto m992:pl-7 sm-480:pl-6">
            <div className="w-full h-auto bg-white sm-480:pb-[70px] w-1920:px-0 md:px-8 pr-[1px]">
              <p className="py-[50px] sm-480:text-px32 font-bold text-xl lg:text-black text-primary w-full text-center">
                {param.id ? "SỬA THÔNG TIN DỊCH VỤ" : "THÊM DỊCH VỤ MỚI"}
              </p>
              <LableContent content="Tải lên ảnh dịch vụ" />
              <div className="w-full flex flex-wrap xl:justify-start justify-between sm-480:mb-12 mb-[30px]">
                <div className="w-1920:w-[220px] xl:w-1/4 w-[48%] border-2 border-dashed border-primary rounded-md sm-480:h-[120px] h-[94px] flex items-center justify-center mb-[10px]">
                  <label
                    htmlFor="file"
                    className="flex flex-col cursor-pointer items-center justify-center"
                  >
                    <img
                      src={`${hostBE}/fe/addImage.png`}
                      alt="taianh"
                      className="w-auto h-auto mb-3"
                    />
                    <span className="font-normal text-primary sm-480:text-base text-sm">
                      Tải ảnh lên
                    </span>
                  </label>
                  <input
                    type="file"
                    onChange={(event) => {
                      handleChoseFile(event);
                    }}
                    id="file"
                    className="hidden"
                  />
                </div>

                {preview.length > 0 &&
                  preview.map((item: string, index: number) => {
                    return (
                      <div
                        key={index}
                        className="imagepreview w-1920:w-[220px] xl:w-1/4 w-[48%] lg:mb-0 mb-[10px] border-none rounded-md sm-480:h-[120px] h-[94px] xl:px-3 px-0"
                      >
                        <div className="w-full h-full relative rounded-md">
                          <div className="rounded-md border-solid border-4 border-text-primary img-delete absolute z-20 w-full h-full top-0 left-0 bg-bg-lightgray">
                            <div className="relative w-full h-full">
                              <img
                                onClick={() => {
                                  handleDeletePreview(index);
                                }}
                                className="absolute top-3 right-5 cursor-pointer"
                                src={`${hostBE}/fe/delete_icon.png`}
                                alt="delete"
                              />
                              <img
                                className="absolute bottom-3 left-5 cursor-pointer"
                                src={`${hostBE}/fe/startround.png`}
                                alt="anh"
                              />
                            </div>
                          </div>
                          <img
                            src={item}
                            alt="ảnh"
                            className="w-full h-full rounded-md"
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>

              {/* tên dịch vụ input */}
              <div className="w-full sm-480:mb-12 mb-[30px]">
                <LableContent content="Tên dịch vụ" />
                <input
                  value={producInput.titleVi}
                  name="titleVi"
                  onChange={(event) => {
                    handleInput(event);
                  }}
                  type="text"
                  className="w-full px-5 py-3 sm-480:text-base text-sm focus:outline-none border border-border-gray rounded-md"
                />
              </div>
              {/* danh mục dịch vụ input */}

              {categoryList.length > 0 && (
                <div className="w-full sm-480:mb-12 mb-[30px]">
                  <LableContent content="Danh mục dịch vụ" />
                  <select
                    value={producInput.categoryId}
                    name="categoryId"
                    onChange={(event) => {
                      handleSelect(event);
                    }}
                    className="w-full px-5 py-3 sm-480:text-base text-sm text-primary focus:outline-none border border-border-gray rounded-md"
                  >
                    {categoryList.map((item, index) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {isEnglish ? item.nameVi : item.nameEn}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
              {/* mô tả dịch vụ input */}

              <div className="w-full sm-480:mb-12 mb-[30px]">
                <LableContent content="Mô tả dịch vụ" />
                <textarea
                  rows={10}
                  cols={100}
                  value={producInput.descriptionVi}
                  name="descriptionVi"
                  onChange={(event) => {
                    handleInput(event);
                  }}
                  className="p-3 focus:outline-text-primary sm-480:text-base text-sm rounded-[10px] border border-border-gray w-full"
                ></textarea>
              </div>

              <div className="w-full sm-480:mb-12 mb-[30px]">
                <LableContent content="Chi tiết dịch vụ" />

                <p className="mb-4 text-primary sm-480:text-base text-sm font-normal">
                  Văn bản chi tiết dịch vụ
                </p>

                <div className="w-full h-auto flex flex-col mb-7 border border-border-gray ">
                  <Editor
                    content={contentvi}
                    setContent={setContentVi}
                    editorRef={refNewVi}
                    listImageFiles={listImageFiles}
                  />
                </div>

                <LableContent content="Thông số kĩ thuật" />

                <div className="w-full mb-20">
                  <table className="w-full border-collapse relative">
                    <tbody>
                      {producInput.specificationList.map((item, index) => {
                        return (
                          <Fragment key={index}>
                            <tr className="relative">
                              <td colSpan={2} className="  w-full">
                                <input
                                  name="nameVi"
                                  onChange={(event) =>
                                    handleChangeTitle(event, index)
                                  }
                                  value={item.nameVi}
                                  type="text"
                                  placeholder="Title ..."
                                  className="w-full rounded-t-md focus:outline-none border-none bg-primary text-white sm-480:text-base text-sm font-bold p-3"
                                />
                              </td>
                            </tr>
                            {item.specificationDetailList.map(
                              (itemTd, indexTd) => {
                                return (
                                  <tr key={indexTd}>
                                    <td className="border border-border-gray w-2/5">
                                      <input
                                        value={itemTd.propertiesVi}
                                        onChange={(event) =>
                                          handleChangeTd(event, index, indexTd)
                                        }
                                        name="propertiesVi"
                                        type="text"
                                        placeholder="Name ..."
                                        className="w-full p-3 focus:outline-none sm-480:text-base text-sm"
                                      />
                                    </td>
                                    <td className="border border-border-gray w-3/5 ">
                                      <input
                                        onChange={(event) =>
                                          handleChangeTd(event, index, indexTd)
                                        }
                                        value={itemTd.valueVi}
                                        name="valueVi"
                                        type="text"
                                        placeholder="Content ..."
                                        className="w-full p-3 focus:outline-none sm-480:text-base text-sm"
                                      />
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                            {mapTr(item.specificationDetailList, index, true)}
                          </Fragment>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan={2}>
                          <div className="absolute bottom-[-20px] right-0 flex">
                            {producInput.specificationList.length < 5 && (
                              <div
                                onClick={handleAddTable}
                                className="cursor-pointer mr-3"
                              >
                                <AiOutlinePlusCircle />
                              </div>
                            )}

                            {producInput.specificationList.length > 1 && (
                              <div
                                onClick={handleDeleteTable}
                                className="cursor-pointer"
                              >
                                <AiOutlineMinusCircle />
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>

              <div className="text-right mb-[60px]">
                <button
                  onClick={() => {
                    navigator("/quanly/sanpham");
                  }}
                  className="2xl:py-[18px] py-[14px] mr-3 2xl:w-[180px] sm-390:w-[150px] w-[100px] border rounded-md border-text-lightred text-text-lightred font-medium sm-480:text-base text-sm text-center"
                >
                  Hủy
                </button>
                <button
                  ref={navigationNextRef}
                  onClick={slideToTop}
                  className="2xl:py-[18px] cursor-pointer py-[14px] inline-block font-medium 2xl:w-[180px] sm-390:w-[150px] w-[100px] border rounded-md bg-primary text-white sm-480:text-base text-sm text-center"
                >
                  Tiếp theo
                </button>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="w-full">
          <div className="w-full flex justify-end h-auto m992:pl-7 sm-480:pl-6 ml-1">
            <div className="w-full h-auto bg-white sm-480:pb-[70px] w-1920:px-0 md:px-8 sm-480:mr-2 mr-[6px]">
              <p className="py-[50px] sm-480:text-px32 font-bold text-xl lg:text-black text-primary w-full text-center">
                {listPreviewImages.length > 0
                  ? "EDIT PRODUCT DETAIL"
                  : "ADD NEW PRODUCT"}
              </p>
              <LableContent content="Upload product picture" />
              <div className="w-full flex flex-wrap xl:justify-start justify-between sm-480:mb-12 mb-[30px]">
                <div className="w-1920:w-[220px] xl:w-1/4 w-[48%] border-2 border-dashed border-primary rounded-md sm-480:h-[120px] h-[94px] flex items-center justify-center mb-[10px]">
                  <label className="flex flex-col cursor-not-allowed items-center justify-center">
                    <img
                      src={`${hostBE}/fe/addImage.png`}
                      alt="taianh"
                      className="w-auto h-auto mb-3"
                    />
                    <span className="font-normal text-primary sm-480:text-base text-sm">
                      Upload picture
                    </span>
                  </label>
                </div>

                {preview.length > 0 &&
                  preview.map((item: string, index: number) => {
                    return (
                      <div
                        key={index}
                        className="w-1920:w-[220px] xl:w-1/4 w-[48%] lg:mb-0 mb-[10px] border-none rounded-md sm-480:h-[120px] h-[94px] xl:px-3 px-0"
                      >
                        <img
                          src={item}
                          alt="ảnh"
                          className="w-full h-full rounded-md"
                        />
                      </div>
                    );
                  })}
              </div>

              {/* tên dịch vụ input */}
              <div className="w-full sm-480:mb-12 mb-[30px]">
                <LableContent content="Product" />
                <input
                  value={producInput.titleEn}
                  name="titleEn"
                  onChange={(event) => {
                    handleInput(event);
                  }}
                  type="text"
                  className="w-full px-5 py-3 sm-480:text-base text-sm focus:outline-none border border-border-gray rounded-md"
                />
              </div>
              {/* danh mục dịch vụ input */}

              {categoryList.length > 0 && (
                <div className="w-full sm-480:mb-12 mb-[30px]">
                  <LableContent content="Product type" />

                  <select
                    value={producInput.categoryId}
                    name="categoryId"
                    onChange={(event) => {
                      handleSelect(event);
                    }}
                    className="w-full px-5 py-3 sm-480:text-base text-sm text-primary focus:outline-none border border-border-gray rounded-md"
                  >
                    {categoryList.map((item, index) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.nameEn}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
              {/* mô tả dịch vụ input */}

              <div className="w-full sm-480:mb-12 mb-[30px]">
                <LableContent content="Product Description" />
                <textarea
                  rows={10}
                  cols={100}
                  value={producInput.descriptionEn}
                  name="descriptionEn"
                  onChange={(event) => {
                    handleInput(event);
                  }}
                  className="p-3 focus:outline-text-primary sm-480:text-base text-sm rounded-[10px] border border-border-gray w-full"
                ></textarea>
              </div>

              <div className="w-full sm-480:mb-12 mb-[30px]">
                <LableContent content="Product Information" />

                <p className="mb-4 text-primary sm-480:text-base text-sm font-normal">
                  Product detail
                </p>
                <div className="w-full h-auto flex flex-col mb-7 border border-border-gray ">
                  <Editor
                    content={contentEn}
                    setContent={setContentEn}
                    editorRef={refNewEn}
                    listImageFiles={listImageFilesEn}
                  />
                </div>
                <LableContent content="Specifications" />

                <div className="w-full mb-20">
                  <table className="w-full border-collapse">
                    <tbody>
                      {producInput.specificationList.map((item, index) => {
                        return (
                          <Fragment key={index}>
                            <tr>
                              <td colSpan={2} className="  w-full">
                                <input
                                  name="nameEn"
                                  onChange={(event) =>
                                    handleChangeTitle(event, index)
                                  }
                                  value={item.nameEn}
                                  type="text"
                                  placeholder="Title ..."
                                  className="w-full rounded-t-md focus:outline-none border-none bg-primary text-white sm-480:text-base text-sm font-bold p-3"
                                />
                              </td>
                            </tr>
                            {item.specificationDetailList.map(
                              (itemTd, indexTd) => {
                                return (
                                  <tr key={indexTd}>
                                    <td className="border border-border-gray w-2/5">
                                      <input
                                        value={itemTd.propertiesEn}
                                        onChange={(event) =>
                                          handleChangeTd(event, index, indexTd)
                                        }
                                        name="propertiesEn"
                                        type="text"
                                        placeholder="Name ..."
                                        className="w-full p-3 focus:outline-none sm-480:text-base text-sm"
                                      />
                                    </td>
                                    <td className="border border-border-gray w-3/5 ">
                                      <input
                                        onChange={(event) =>
                                          handleChangeTd(event, index, indexTd)
                                        }
                                        value={itemTd.valueEn}
                                        name="valueEn"
                                        type="text"
                                        placeholder="Content ..."
                                        className="w-full p-3 focus:outline-none sm-480:text-base text-sm"
                                      />
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                            {mapTr(item.specificationDetailList, index, false)}
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="text-right mb-[60px]">
                <button
                  ref={navigationPrevRef}
                  onClick={slideToTop}
                  className="2xl:py-[18px] py-[14px] mr-3 2xl:w-[180px] sm-390:w-[150px] w-[100px] border rounded-md border-text-lightred text-text-lightred font-medium sm-480:text-base text-sm text-center"
                >
                  Go back
                </button>
                <button
                  disabled={isDisable}
                  onClick={handleSubmit}
                  className={clsx(
                    isDisable && "cursor-not-allowed",
                    "2xl:py-[18px] cursor-pointer py-[14px] inline-block font-medium 2xl:w-[180px] sm-390:w-[150px] w-[100px] border rounded-md bg-primary text-white sm-480:text-base text-sm text-center"
                  )}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default ProducSlide;
