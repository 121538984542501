
import  clsx  from 'clsx';
import { useAppSelector } from '../../hooks/hook';
import useInView from '../../hooks/useInView';
import { hostBE } from '../../types/host';
type Props = {
    index:number
    isOver: boolean,
    value: {
        titleVi: string
        titleEn: string
        descriptionVi: string
        descriptionEn: string
    }
}

export default function ValueCoreItem({index, isOver, value}:Props) {
    const {isInView, ref} = useInView();
    const translate = useAppSelector(state => state.translateSlice)
    return (
        <div ref={ref} className={clsx("w-full h-auto mb-[30px] lg:mb-[50px] max-w-full pl-[30px]", {"sc<992:flex sc<992:justify-end":index%2!==0, "animate__animated animate__fadeInUp":isInView})}>
            <div className={clsx("relative w-[760px]  h-auto max-w-full ", {"core-event":index%2===0 && !isOver,"core-old":index%2!==0 && !isOver })}>
            <div className="w-[760px] lssm:h-auto  max-w-full lg:min-h-[150px] border-[1px] px-[24px] bg-white-color border-solid border-primary">
                <p className="lssm:mt-[30px] sm:mt-[60px] md:px-[24px] lssm:text-px14 sm-480:text-px16 text-justify">
                         {translate.isEnglish ? value.descriptionVi : value.descriptionEn}
                </p>
            </div>

                <div className="absolute left-[-40px] lssm:top-[-20px] md:top-[-25px] z-[2]">
                    <img src={`${hostBE}/fe/header.png`} className='lssm:max-w-[100%]' alt="" />
                    <p className="absolute top-[50%] translate-y-[-50%] lssm:left-[30px] leading-[1.2] sm:left-[60px] lssm:text-px12 lsm-380:text-px16 md:text-[20px] 2xl:text-[32px] font-medium uppercase text-white-color">{translate.isEnglish ? value.titleVi : value.titleEn}</p>
                </div>

                <div className="absolute left-[-40px] lssm:top-[-12px] sm:top-[-12px] z-[-1]">
                    <img src={`${hostBE}/fe/sub_header.png`} className='lssm:max-w-[75%] lg:max-w-[79%]' alt="" />
                </div>
                <div className="absolute top-0 left-0 z-[1]">
                    <img src={`${hostBE}/fe/shadow_header.png`} className='lssm:max-w-[78%] lg:max-w-[79%]' alt="" />
                </div>
            </div>

        </div>

    )
}