import Banner from "../../containers/Project/Banner";
import Project from "../../containers/Project";
import MultiContact from "../../components/MultiContact";


export default function ProjectPage() {

    return (
        <div className="">
           <Banner />
           <div className="mt-[133px] sc>768:mt-[30px]">
             <Project />
            </div>
            <MultiContact />
        </div>
    )
}