import _debounce from "lodash/debounce";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../components/Button";
import CartProduct from "../../components/cart/CartProduct";
import DialogConfirm from "../../components/DialogConfirm";
import Loadding from "../../components/Loadding/Loadding";
import InputSearch from "../../components/ManagerComponent/InputSearch";
import TitlePage from "../../components/ManagerComponent/TitlePage";
import Pagination from "../../components/Pagination";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { hideModal, showModal } from "../../reducers/modal";
import { pushPopup } from "../../reducers/popupSlice";
import {
  getProducts,
  searchProducts,
  setCurrentPage,
} from "../../reducers/products";
import producService from "../../services/products";
import { hostBE } from "../../types/host";

function ProductManager() {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const navigator = useNavigate();
  const dispatch = useAppDispatch();
  const { productList, total, loadding, error, currentPage } = useAppSelector(
    (state) => state.productSlice
  );
  const { isEnglish } = useAppSelector((state) => state.translateSlice);
  const [valueSearch, setValueSearch] = useState<string>("");
  const handleChangeInputSearch = (inputData: string) => {
    navigator("");
    dispatch(setCurrentPage(1));
    setValueSearch(inputData);
  };
  const handleAddProduct = () => {
    navigator("add");
  };
  const handleEditProducts = (id: number) => {
    navigator(`edit/${id}`);
  };

  const deleteProduct = async (id: number) => {
    try {
      const result = await producService.deleteProduct(id);
      if (result) {
        dispatch(
          pushPopup({
            type: "SUCCESS",
            message: isEnglish
              ? "Xóa dịch vụ thành công"
              : "Delete product successfully",
          })
        );
        if (currentPage === 1) {
          dispatch(getProducts({ page: currentPage - 1, limit: 12 }));
        } else {
          dispatch(setCurrentPage(1));
        }
        navigator("");
      }
    } catch (error) {
      dispatch(
        pushPopup({
          type: "WARNING",
          message: isEnglish
            ? "Không xóa được dịch vụ!"
            : "Product cannot be deleted!",
        })
      );
    }
    dispatch(hideModal());
  };

  const handleDelete = (id: number) => {
    dispatch(
      showModal(
        <DialogConfirm
          message="Bạn có chắc muốn xóa dịch vụ này không ?"
          onClick={() => {
            deleteProduct(id);
          }}
        />
      )
    );
  };
  const handleAddCategory = () => {
    navigator("/quanly/category/add");
    // dispatch(showModal(<CategoryModal />));
  };

  // handle debounce iuputSearch
  const handleDebounceFn = (keySearch: string) => {
    if (keySearch != "") {
      dispatch(
        searchProducts({
          keySearch: keySearch,
          option: {
            page: currentPage - 1,
            limit: 12,
          },
        })
      );
    }
  };
  const debounceFn = useCallback(_debounce(handleDebounceFn, 1000), [
    valueSearch,
    currentPage,
  ]);
  useEffect(() => {
    if (searchParams.get("page")) {
      const numberPage = Number(searchParams.get("page"));
      setCurrentPage(numberPage);
    }
  }, [searchParams]);

  useEffect(() => {
    if (valueSearch != "") {
      if (currentPage == 1) {
        debounceFn(valueSearch);
      } else {
        dispatch(
          searchProducts({
            keySearch: valueSearch,
            option: {
              page: currentPage - 1,
              limit: 12,
            },
          })
        );
      }
    } else {
      dispatch(getProducts({ page: currentPage - 1, limit: 12 }));
    }
    return () => {
      debounceFn.cancel();
    };
  }, [currentPage, valueSearch]);

  return (
    <div className="w-full">
      <TitlePage content="titleManager.productTitle" />
      <div className="w-full flex sm:flex-row sm:flex-wrap flex-col justify-between items-center px-[10px]">
        <div className="2xl:w-2/4 w-full 2xl:mb-0 mb-4">
          <InputSearch
            ChangeInputFc={(param) => {
              handleChangeInputSearch(param);
            }}
          />
        </div>

        <div className="2xl:w-[24%] sm:w-[48%] w-full  lg:mb-0 sm:mb-0 mb-4">
          <Button
            color="empty"
            onClick={() => {
              handleAddCategory();
            }}
            className="w-full w-1920:py-[18px] 2xl:py-[19px] py-4 px-4 w-1920:px-10 2xl:px-0  flex bg-bg-lightgray border-none"
            disabled={false}
            type="button"
          >
            <img className="mr-3" src={`${hostBE}/fe/add.png`} alt="add" />
            <p className="sm-480:text-base text-sm font-medium text-bg_blue_bold">
              {t("button.button_addCatoriesProduct")}
            </p>
          </Button>
        </div>

        <div className="2xl:w-[24%] sm:w-[48%] w-full">
          <Button
            color="primary"
            onClick={() => {
              handleAddProduct();
            }}
            className="w-full w-1920:py-[18px] 2xl:py-[19px] py-4 px-4 w-1920:px-10 2xl:px-0 flex "
            disabled={false}
            type="button"
          >
            <img className="mr-3" src={`${hostBE}/fe/add1.png`} alt="add" />
            <p className="sm-480:text-base text-sm font-medium text-white">
              {t("button.button_addProduct")}
            </p>
          </Button>
        </div>
      </div>
      {error ? (
        <div className="w-full sm:py-[60px] py-10 h-[200px] flex items-center justify-center">
          <p className="w-full text-text-red text-lg text-center">{error}</p>
        </div>
      ) : loadding ? (
        <div className="w-full sm:py-[60px] py-10 h-screen flex items-center justify-center">
          <Loadding />
        </div>
      ) : (
        <Fragment>
          <div className="w-full h-auto sm:py-[60px] py-10 flex flex-wrap">
            {productList.length > 0 &&
              productList.map((product, index) => {
                return (
                  <CartProduct
                    key={index}
                    cartContent={product}
                    outstanding={true}
                    isGrid={true}
                    onClick={() => handleEditProducts(product.id)}
                    onClickDelete={() => handleDelete(product.id)}
                  />
                );
              })}
          </div>
          <div className="w-full  flex items-center justify-end mb-24">
            {total > 0 && (
              <Pagination
                total={Math.ceil(total / 12)}
                currenPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default ProductManager;
