import {useState, useEffect} from "react";
import { useTranslation } from "react-i18next";
import SliderVideo from "../../components/Home/SliderVideo";
import { linkYoutube } from "../../types";
import partnerService from "../../services/partner";

export default function SlideCompany() {
    
    const [t] = useTranslation();
    const [urlImg, setUrlImg] = useState<linkYoutube[]>([]);


    useEffect(()=> {
        partnerService.findLinkYoutube().then(data => {
           setUrlImg(data)
        })
      
   }, [])

    return urlImg.length > 0 ? (
        <div className="sc<992:my-[100px] sc991:my-[30px]">
            <div className="lssm:px-[24px] md:px-[80px]  xl:px-[50px]  w-1920:px-[162px] lssm:mt-[30px]  md:mt-[78px]  ">
            <h2 className="text-[#11213F]  sc991:text-text-primary lssm:text-px20 md:text-[32px]  xl:text-[34px] 2xl:text-[48px]  sc991:text-center font-bold   mb-[40px] pl-[20px] border-l-[25px] border-solid uppercase border-primary sc991:border-none block leading-[35px]">
                {t("home.topic.topic4")}
            </h2>
            <SliderVideo />
            </div>
        </div>
    ) : <div></div>
}