import { useTranslation } from "react-i18next";
import Map from "../components/Map";
import {GoLocation} from "react-icons/go"
import {MdOutlinePhone, MdOutlineLanguage, MdLocationOn, MdFacebook} from "react-icons/md"
import Button from "../components/Button";
import { useFormik } from "formik";
import * as Yup from "yup"
import contactService from "../services/contact";
import { useAppDispatch } from "../hooks/hook";
import { showModal } from "../reducers/modal";
import ModalMap from "../components/ModalMap";
import MapPlace from "../components/MapPlace";
import { AiOutlineMail } from "react-icons/ai";
import { hostBE } from "../types/host";
import { pushPopup } from "../reducers/popupSlice";

export default function Footer () {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const formik = useFormik({
        initialValues: {
                fullname: '',
                phone: "",
                email: "",
                note: "",
        },
        validationSchema: Yup.object({
                fullname: Yup.string().required(t("validate.error.required")).min(5, t("validate.error.min", {name: t("contact.form.name"), min: 5})),
                phone: Yup.string().required(t("validate.error.required")).matches(/^[0-9]{10}$/, t("validate.error.pattern", {name: t("contact.form.phone")})),
                email: Yup.string().required(t("validate.error.required")).matches(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, t("validate.error.pattern", {name: t("career.form.email")})),
                note: Yup.string().required(t("validate.error.required")).min(5, t("validate.error.min", {name: t("contact.form.help"), min: 5})),
        }),
        onSubmit: (values)=> {
                contactService.postContact(values).then(()=> {
                        dispatch(pushPopup({
                                type: "SUCCESS",                         
                                message: t("contact.form.postSuccess")
                        }))

                        resetForm()
                }).catch(()=> {
                        dispatch(pushPopup({
                                type: "ERROR",
                                message: t("contact.form.postFail")
                        }))
                })
        }
})

        const resetForm = () => {
            formik.resetForm();
        }


    const showModalMap = () => {
        dispatch(showModal(<ModalMap Map={<Map width={"100%"} height={"100%"}  />} />))
    }

    const showModalMapPlace = () => {
        dispatch(showModal(<ModalMap Map={<MapPlace width={"100%"} height={"100%"}  />} />))
    }

    return (
        <>

        <div className="lssm:px-[24px] md:px-[80px] lg:pb-[40px]  lg:px-[100px] 2xl:px-[221px]  bg-footer text-white text-white-color sc991:text-white-color overflow-x-hidden ">
            <div className="flex lssm:flex-col lg:flex-row lg:gap-x-[50px] 2xl:gap-x-[150px] lssm:text-[12px] lg:text-[18px] relative pb-[24px] lssm:pt-[30px] lssm:pb-[60px] lg:pb-[30px] lg:pt-[50px] ">
                <div className="h-full flex-1">
                    <div className="rounded-[5px]">
                        <img  src={`${hostBE}/fe/logo_footerrockyplateau.png`} className="sc991:w-[120px] w-auto" alt="logo" />
                    </div>
                    <div className="flex items-center lg:items-baseline mt-5">
                    <span className="text-inherit mr-5"><GoLocation /></span>
                        <span className="w-fit">
                        {t("footer.footer_1.content6")}
                        </span>
                    </div>
                    <div className="flex items-center mt-5">
                    <span className="text-inherit text-xl"><MdLocationOn /></span> <Button onClick={showModalMap} color="empty" className="rounded-[10px] sc>768:text-px13 bg-white-color ml-5 text-text-primary">{t("footer.see_map")}</Button>
                    </div>
                    <p className="text-white text-[10px] w-max uppercase hidden mt-[24px]  lg:block">
                        <span className="font-bold"> Copyright @2023 SMESTORE - A PRODUCT OF
                        ALADIN TECHNOLOGY </span>
                    </p>
                </div>

                <div className="w-max lssm:mt-[24px] lg:mt-0">
                    <h3 className="xl:text-[20px] lssm:text-[18px] font-medium lg:text-normal1 lg:font-semibold text-inherit w-full">
                    {t("footer.footer_1.title1")}
                    </h3>
                    <div className="lssm:mt-[7px] lg:mt-[32px] text-inherit sc991:flex sc991:flex-col">
                        <div className="flex ">
                            <span className="text-[20px] text-inherit text-xl"><MdOutlinePhone /></span><a href="tel:0978159123" className="ml-5 font-normal cursor-pointer">{t("footer.footer_1.content2")}</a>
                        </div>
                        <div className="flex mt-5 items-center">
                            <span className="text-[20px] text-inherit text-xl"><AiOutlineMail /></span> <a href={`mailto:${t("footer.footer_1.content3")}`} target="blank" className="ml-5 font-normal">{t("footer.footer_1.content3")}</a>
                        </div>
                        <div className="flex mt-5 items-center">
                            <span className="text-[20px] text-inherit text-xl"><MdOutlineLanguage /></span> <a href={t("footer.footer_1.content4")} target="blank" className="ml-5 font-normal">{t("footer.footer_1.content4")}</a>
                        </div>
                        <div className="flex mt-5 items-center">
                            <span className="text-[20px] text-inherit text-xl"><MdFacebook /></span> <a href={t("footer.footer_1.content5")} target="blank" className="ml-5 font-normal">{t("footer.footer_1.content5")}</a>
                        </div>
                        
                    </div>
                </div>
                <div className="w-max lssm:mt-[30px] lg:mt-0">
                    <h3 className="xl:text-[20px] lssm:text-[18px] font-medium lg:text-normal1 lg:font-semibold text-inherit uppercase">
                    {t("footer.footer_1.title2")}
                    </h3>
                    <p className="text-[12px]">
                    {t("footer.footer_1.content7")}
                    </p>
                    <form onSubmit={formik.handleSubmit} className="mt-[16px] text-inherit sc991:flex  sc991:flex-col text-normalgit">
                        <div>
                            <input
                            type="text"
                            name="fullname"
                            placeholder= {t("footer.footer_1.form1")}
                            value={formik.values.fullname}
                            onChange={formik.handleChange}
                            className="w-full outline-none border-b bg-transparent border-b-white focus:border-b-lightGreen duration-200"
                            />
                            <span className="text-[14px] text-white mt-1">
                            {formik.errors.fullname}
                            </span>
                        </div>

                        <div className="mt-[16px]">
                            <input
                            type="text"
                            name="phone"
                            placeholder= {t("footer.footer_1.form2")}
                            value={formik.values.phone} 
                            onChange={formik.handleChange}
                            className="w-full  outline-none border-b bg-transparent border-b-white focus:border-b-lightGreen duration-200"
                            />
                            <span className="text-[14px] text-white mt-1">
                            {formik.errors.phone}
                            </span>
                        </div>

                        <div className="mt-[16px]">
                            <input
                            type="email"
                            name="email"
                            placeholder= {t("footer.footer_1.form3")}
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            className="w-full outline-none border-b bg-transparent border-b-white focus:border-b-lightGreen duration-200"
                            />
                            <span className="text-[14px] text-white mt-1">
                            {formik.errors.email}
                            </span>
                        </div>

                        <div className="mt-[16px]">
                            <input
                            type="text"
                            name="note"
                            placeholder= {t("footer.footer_1.form4")}
                            value={formik.values.note}
                            onChange={formik.handleChange}
                            className="w-full outline-none border-b bg-transparent border-b-white focus:border-b-lightGreen duration-200"
                            />
                            <span className="text-[14px] text-white mt-1">
                            {formik.errors.note}
                            </span>
                        </div>
                        <div className="mt-[16px]">
                            <button className="btn-footer w-[134px] h-[24px] rounded lg:w-[210px] lg:h-[40px] lg:rounded-[10px] text-[12px] lg:text-[18px] text-text-primary bg-white after:top-1 after:left-1 lg:after:top-[7px] lg:after:left-[7px]" type="submit">
                            {t("contact.form.btn_submit")}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        </>
    )

}