import { AnyAction } from "@reduxjs/toolkit"
import HttpService from "../configs/api"
import { ResponsiveData } from "../types"
import { Banner, linkYoutube } from "../types/banner"
import getApi from "./getApi"


const partnerService = {
    addLinkYoutube: (data: linkYoutube) : Promise<linkYoutube> => {

        const url = getApi("linkyoutube")
        return HttpService.axiosClient.post(url, data);
    },
    findLinkYoutube: ():Promise<linkYoutube[]> => {
        const url = getApi("linkyoutube")
        return HttpService.axiosClient.get(url)
    },
    deleteLinkYoutube: (id:number):Promise<AnyAction> => {
        const url = getApi(`linkyoutube/${id}`);
        return HttpService.axiosClient.delete(url)
    },
    create: (banner: Banner[]):Promise<ResponsiveData<Banner>> => {
        const url = getApi("partner")
            return HttpService.axiosClient.post(url, banner);
    },
    find: ():Promise<Banner[]> => {
        const url = getApi("partner")
        return HttpService.axiosClient.get(url)
    },
    delete: (id:number):Promise<boolean> => {
        const url = getApi(`partner/image/${id}`);

        return HttpService.axiosClient.delete(url)


    }
}


export default partnerService