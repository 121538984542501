import Button from "../../../components/Button";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdAddPhotoAlternate, MdClear } from "react-icons/md";
import DialogConfirm from "../../../components/DialogConfirm";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import { hideModal, showModal } from "../../../reducers/modal";
import { createPartner, deletePartner, getPartner } from "../../../reducers/partner";
import { pushPopup } from "../../../reducers/popupSlice";
import uploadService from "../../../services/uploadImage";
import { linkYoutube, UrlResponsive } from "../../../types";
import partnerService from "../../../services/partner";

export default function ManagerPartner() {

    const partner = useAppSelector(state => state.partnerSlice);
    const dispatch = useAppDispatch();
    const [t] = useTranslation();
    const [urlImg, setUrlImg] = useState<linkYoutube[]>([]);
    const [valueInput, setValueInput] = useState<string>("");
    
    const translate = useAppSelector(state => state.translateSlice);

    const handleChangeInput = (event: ChangeEvent<HTMLInputElement>)=> {
        const value = event.target.value;
        setValueInput(value)
    }

    const postText = async(data: any)=> {
      partnerService.addLinkYoutube({
            imageUrl: data,
            imagePath: ""
        }).then((data) => {
            setUrlImg([data, ...urlImg])
        })
       
        
    }
    useEffect(()=> {
         partnerService.findLinkYoutube().then(data => {
            setUrlImg(data)
         })
       
    }, [])
    

    const handleBanner = (event:ChangeEvent<HTMLInputElement>) => {
        //@ts-ignore
        const listFile = event.currentTarget.files
        if(listFile !== null) {
            const files = Array.from(listFile);
            const formData = new FormData();
            files.forEach((file:File, index) => {
                if(file.size < 26675200){
                    formData.append("file", file);
                }else {
                    if(files.length >= 2) {
                        dispatch(pushPopup({
                            message: t("notify.min_images", {no: index  + 1,size: "25MB"}),
                            type: "WARNING"
                        }))
                    } else {
                        dispatch(pushPopup({
                            message: t("notify.min_image", {size: "25MB"}),
                            type: "WARNING"
                        }))
                    }
                }
            })
    
            uploadService.upload(formData).then((urls:UrlResponsive)=> {
                    const bannerUrl = urls.list.map(item => ({imagePath: item.image_path, imageUrl: item.image_url}))
                    dispatch(createPartner(bannerUrl));
            })

        }

    }

    const onInputClick = (e:React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        e.currentTarget.value = ""
    }


    const deleteBannerModal = (id:number)=> {
        dispatch(showModal(<DialogConfirm message='Bạn có muốn xóa Video này không?' onClick={()=> handleDelete(id)}  />))
    }

    const handleDelete = async (id:number)=> {
        // deleteLinkYoutube
        partnerService.deleteLinkYoutube(id).then((data) => {
            const newUrl = urlImg
            const index = newUrl.findIndex(i => i.id === id)
            newUrl.splice(index, 1)
            setUrlImg([...newUrl])
            dispatch(pushPopup({
                message: "Xóa thành công",
                type: "SUCCESS"
            }))
        }).catch(() => {
            dispatch(pushPopup({
                message: "Xóa thất bại",
                type: "ERROR"
            }))
        }).finally(() => {
            dispatch(hideModal())
        })
    }


    return <div className="mb-[80px]">
        <p className="text-px16 text-text-primary md:text-px20 font-bold lssm:mb-[18px] md:mb-[40px] mt-[112px]">{t("dashboard.home.customer.title")}</p>
        <div className="lssm:mb-[18px] md:mb-[30px] mt-[30px]">
            {/* <label htmlFor='home-partner' className="w-full  border-primary bg-primary text-white-color lssm:h-[90px] md:h-[180px] flex flex-col items-center justify-center rounded-[12px]">
              
              <span className="lssm:text-[32px] md:text-[48px]"><MdAddPhotoAlternate /></span>
                  <span className="lssm:mt-[18px] md:mt-[32px] text-px12 lsm-380:text-px14 md:text-px16">{t("dashboard.home.customer.upload")}</span>
                  <input onChange={handleBanner} onClick={onInputClick} multiple hidden type="file" id="home-partner" />
            </label> */}
            <input type="text"
                className="w-full px-5 py-3 sm-480:text-base text-sm focus:outline-none border border-border-gray rounded-md"
                placeholder="nhập đường dẫn URL Youtube"
                name="imageUrl"
                value={valueInput}
                onChange={(event)=> {handleChangeInput(event)}} />
        </div>
        <div className="flex mb-[90px] items-center justify-end">
            <Button onClick={()=> postText(valueInput)} type="submit" color="primary" className="ml-3 h-[50px] sc>768:flex-1 md:w-[120px]">{t("button.upload")}</Button>
        </div>
        <div className="grid lssm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-3">

            {
                urlImg.map((item: any, index)=> {
                    console.log('item',item.imageUrl);
                    
                    return (
                        <div key={item.id} className="w-full relative border-2 border-solid border-primary flex flex-col items-center justify-center rounded-[10px]">
                            <span className='absolute top-2 right-2 text-text-decoration text-px24 cursor-pointer flex justify-center items-center w-[30px] h-[30px] bg-white-color rounded-[50%]' onClick={()=>deleteBannerModal(item.id)}>
                                <MdClear />
                            </span>

                            <iframe allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" scrolling="no" allowFullScreen={true}  className="lssm:min-h-[432px] xl:min-h-[432px] block  max-h-[432px]  min-w-full object-cover rounded-[10px]" src={`https://www.youtube.com/embed/${item.imageUrl}`}
             title="ROCKY HAGIANG" ></iframe>
                        </div>
                    )
                })
            }
    </div>
</div>
}