import { ChangeEvent, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/Button";
import Input from "../../../components/InputImage";
import Radio from "../../../components/Radio";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import { feedBackSuccess } from "../../../reducers/contact";
import { hideModal } from "../../../reducers/modal";
import { pushPopup } from "../../../reducers/popupSlice";
import contactService from "../../../services/contact";
import { Contact } from "../../../types";

type Props = {
    contact: Contact
}

export default function ModalResponse ({contact}:Props) {
    const dispatch = useAppDispatch();
    const [feedBack, setFeedBack] = useState<string>(contact.feedback??"");
    const [message, setMessage] = useState("");
    const [t] = useTranslation();
    const {isEnglish} = useAppSelector(state => state.translateSlice)

    const handleChange = (event:ChangeEvent<HTMLTextAreaElement>) => {
        setFeedBack(event.currentTarget.value);
        setMessage("")
    }

    const handleSubmit = (event:FormEvent) => {
        event.preventDefault();
        if(feedBack.trim() === "") {
            setMessage( isEnglish ? "Vui lòng điền phản hồi của bạn" : "Please fill in your feedback")
        }else {
            contactService.feedBackContact({id: contact.id, feedback: feedBack}).then((data:Contact)=> {
                dispatch(feedBackSuccess(data));
                dispatch(hideModal())
                dispatch(pushPopup({
                    type: "SUCCESS",
                    message: t("notify.success", {name: t("contact.response")})
                }))
            }).catch(error => {
                dispatch(pushPopup({
                    type: "ERROR",
                    message: t("notify.error", {name: t("contact.response")})
                }))
            })
        }
    }

    return (
        <div className="lssm:w-[380px] sm:w-[500px] md:w-[600px] lg:w-[840px]  rounded-[20px] bg-white-color py-[30px] lssm:px-[24px] md:px-[40px] xl:px-[80px]">
                <h2 className="lssm:text-px20 md:text-[32px] text-text-primary font-medium text-center mb-[50px]">{t("dashboard.request.form.title")}</h2>

                <form onSubmit={handleSubmit} className="grid lssm:grid-cols-1 md:grid-cols-2 gap-x-[24px] gap-y-[30px]">
                    <div>
                        <label className="block mb-[12px] text-px16 font-medium text-text-primary">{t("contact.form.name")}</label>
                        <Input value={contact.fullname} readOnly={true}  />
                    </div>

                    <div>
                        <label className="block mb-[12px] text-px16 font-medium text-text-primary">{t("contact.form.phone")}</label>
                        <Input   value={contact.phone} readOnly={true} />
                    </div>

                    <div className="md:col-span-2">
                        <label className="block mb-[12px] text-px16 font-medium text-text-primary">{t("contact.form.email")}</label>
                        <Input    value={contact.email} readOnly={true} />
                    </div>

                    {/* <div className="md:col-span-2">
                        <label className="block mb-[12px] text-px16 font-medium text-text-primary">Ngân sách duyệt chi</label>
                       <div className="flex items-center">
                        <span className="mr-[12px]"><Radio checked={true} id="response"  color="#0058DB" /> </span><span>{contact.budget}</span>
                       </div>
                    </div> */}

                    <div className="md:col-span-2">
                        <label className="block mb-[12px] text-px16 font-medium text-text-primary">{t("contact.form.content_request")}</label>
                        <textarea readOnly={true} value={contact.note} className="border-[1px] px-1 py-1 border-solid border-text-primary outline-none  rounded-[10px] w-full" rows={8}>

                        </textarea>
                    </div>

                        <div className="md:col-span-2">
                            <label className="block mb-[12px] text-px16 font-medium text-text-primary">{t("dashboard.request.form.content_response")}</label>
                            <textarea onChange={handleChange}  value={feedBack}  className="border-[1px] px-1 py-1 border-solid border-text-primary outline-none  rounded-[10px] w-full" rows={8}>

                        </textarea>
                        <small className="mt-2 text-[#FF0000 text-px14]">{message}</small>
                    </div>
                    <div className="md:col-span-2 flex justify-end">
                    <Button onClick={()=>dispatch(hideModal())} color="empty" className="h-[60px] w-[120px]">{t("button.cancel")}</Button>
                    <Button type="submit" color="primary" className="h-[60px] ml-3 w-[120px]">{t("dashboard.request.form.btn_response")}</Button>
                    </div>
                </form>

        </div>
    )
}