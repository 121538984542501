import Button from "../../../components/Button";
import Input from "../../../components/InputImage";
import { hideModal } from "../../../reducers/modal";
import { useAppDispatch } from "../../../hooks/hook";
import { useFormik } from "formik";
import * as Yup from "yup"
import { ChangeEvent, useEffect, useState } from "react";
import Avatar from "../../../components/Avatar";
import uploadService from "../../../services/uploadImage";
import { createSupplier, editSupplier } from "../../../reducers/supplier";
import { FormContact } from "../../../types";
import resizeFile from "../../../common/reSize";
import { pushPopup } from "../../../reducers/popupSlice";
import { hostBE } from "../../../types/host";
import { useTranslation } from "react-i18next";

type Props = {
    type: "ADD" | "EDIT",
    data?: FormContact
}

export default function ModalContact({type, data}:Props) {
    const dispatch = useAppDispatch();
    const [avatarFile, setAvatarFile] = useState<any>(null);
    const [t] = useTranslation();
    const formik = useFormik({
        initialValues: {
            link: "",
            fullname: "",
            avatarUrl: "",
            social: "PHONE"
        },
        validationSchema: Yup.object({
            fullname: Yup.string().required(t("validate.required")),
            link: Yup.string().required(t("validate.required")),
            avatarUrl: Yup.string().required(t("validate.required")),
            social: Yup.string().required(t("validate.required")),
        })
        ,
        onSubmit: async (values) => {
            const formData = new FormData();
            formData.append('file', avatarFile);
            let urlFile:any
            if(avatarFile) {
                urlFile = await uploadService.upload(formData);
            }
                if(type === "ADD") {
                    dispatch(createSupplier({
                        ...values,
                        avatarUrl: urlFile?.list[0].image_url,
                        avatarPath: urlFile?.list[0].image_path,
                        //@ts-ignore
                        social: values.social
                    }))

                }else {

                    dispatch(editSupplier({
                        ...values,
                        avatarUrl: urlFile?.list[0].image_url ?? data?.avatarUrl,
                        avatarPath: urlFile?.list[0].image_path ?? data?.avatarPath,
                        //@ts-ignore
                        social: values.social,
                        id: data?.id
                    }))
                }

        }
    })

    useEffect(() => {
        if(type === "EDIT") {
            formik.setValues({
                fullname: data?.fullname ?? "",
                avatarUrl: data?.avatarUrl ?? "",
                   link: data?.link ?? "",
                social: data?.social ?? "PHONE"
            })
        }
    }, [data])

    const handleChangeFile = async (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.currentTarget.files![0];
        if(file.size < 20000000) {
            const newFile:any = await resizeFile(file, {width: 50, height: 50});
            setAvatarFile(newFile);
            formik.setFieldValue("avatarUrl", URL.createObjectURL(newFile))
        }else {
            dispatch(pushPopup({
                message: t("notify.min_image", {size: "25MB"}),
                type: "WARNING"
            }))
        }
    }

    return (
        <div className="py-[50px] lssm:px-[24px] lssm:w-full md:px-[50px] xl:px-[80px] 2xl:px-[110px] rounded-[20px] md:w-[650px] lg:w-[900px] xl:w-[1120px] 2xl:w-[1360px] max-w-full bg-white-color">
            <h2 className="lssm:text-px20 md:text-[32px] lg:text-[48px] font-medium text-text-decoration text-center">{t("dashboard.contact.create.title")}</h2>
            <form onSubmit={formik.handleSubmit} className="grid grid-cols-1 mt-[66px] gap-y-[30px]">

                <div>
                    <label className="lssm:text-px13 md:text-px18 mb-[12px] block">{t("dashboard.contact.create.choose")}</label>
                    <select name="social" value={formik.values.social} onChange={formik.handleChange} className="w-full border border-solid border-border-color focus:border-primary h-[50px]">
                        <option value="PHONE">{t("dashboard.contact.create.phone")}</option>

                        <option value="GMAIL">Gmail</option>

                        <option value="ZALO">Zalo</option>

                        <option value="FACEBOOK">Facebook</option>
                        <option value="SKYPE">Skype</option>
                        <option value="WHATSAPP">WhatsApp</option>
                    </select>

                </div>

                <div className="flex items-center flex-wrap">
                    <label className="lssm:text-px13 md:text-px18 mb-[12px] block w-full">{t("dashboard.contact.create.upload_a")}</label>
                    <label htmlFor='contact_avatar' className="lssm:w-full md:w-[220px] border-2 border-dashed  border-[#A8A8A8] text-[#A8A8A8] h-[180px] flex flex-col items-center justify-center rounded-[5px]">

                        <img src={`${hostBE}/fe/plusImageGray.png`} alt="" />
                        <span className="mt-[12px]">{t("dashboard.contact.create.upload_i")}</span>
                        <input onChange={handleChangeFile} hidden type="file" id="contact_avatar" />
                    </label>
                    <div className="ml-[30px]">
                        {
                            formik.values.avatarUrl !== "" ? (
                                <Avatar url={formik.values.avatarUrl} size={60} />

                            ) : ""
                        }

                    </div>
                    <p className="w-full mt-2 text-text-lightred">{formik.errors.avatarUrl}</p>
                </div>

                <div>
                    <label className="lssm:text-px13 md:text-px18 mb-[12px] block">{t("dashboard.contact.create.supporter")}</label>
                    <Input value={formik.values.fullname} name="fullname" onChange={formik.handleChange} />
                    <p className="w-full text-text-lightred mt-2">{formik.errors.avatarUrl}</p>

                </div>

                <div>
                    <label className="lssm:text-px13 md:text-px18 mb-[12px] block">{t("dashboard.contact.create.info")}</label>
                    <Input value={formik.values.link} name="link" onChange={formik.handleChange} />
                    <p className="w-full text-text-lightred mt-2">{formik.errors.avatarUrl}</p>

                </div>

                <div className="flex items-center justify-end">
                    <Button type="button" color="empty" onClick={() => dispatch(hideModal())} className="h-[50px] sc>768:flex-1 md:w-[120px]">{t("button.cancel")}</Button>
                    <Button type="submit" color="primary" className="ml-3 h-[50px] sc>768:flex-1 md:w-[120px]">{t("button.upload")}</Button>
                </div>
            </form>
        </div>
    )
}