import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation, Grid } from "swiper";
import clsx from "clsx";
import React, { useEffect, useMemo, useState } from "react";
import {
  MdOutlineArrowBackIosNew,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { useTranslation } from "react-i18next";
import { CgArrowLongRight } from "react-icons/cg";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import useInView from "../../hooks/useInView";
import { changeCategoryChecked } from "../../reducers/categorySlice";
import { getProductActiveHome } from "../../reducers/productPublic";
import { hostBE } from "../../types/host";

import Button from "../Button";
import useViewport from "../../hooks/useViewPort";

const LIMIT = 6;

export default function TopicProduct() {
  const { ref, isInView } = useInView();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const translate = useAppSelector((state) => state.translateSlice);
  const categories = useAppSelector(
    (state) => state.productPublic.productActiveHome
  );
  const [t] = useTranslation();

  // const width = useMemo(() => {
  //   return window.innerWidth;
  // }, []);
  const { width } = useViewport()

  const handleNavigate = (id: number) => {
    navigate("/sanpham");
    dispatch(changeCategoryChecked(id));
  };
  useEffect(() => {
    // if (productActiveHome.length === 0) {
    dispatch(getProductActiveHome());
    // }
  }, []);

  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)

  return (
    <div
      className="lssm:py-[32px] 2xl:py-[78px] h-full lssm:px-[24px] md:px-[80px]  xl:px-[50px]  w-1920:px-[162px]"
      ref={ref}
    >
      <div className="grid grid-cols-1 lg:grid-cols-[minmax(0,2fr)_minmax(0,3fr)] gap-[40px] xl:w-[1200px] h-auto items-center 2xl:w-[1300px] w-1920:w-[1700px] mx-auto">
        <div
          className={clsx(
            "",
            { "animate__animated animate__fadeInLeft": isInView }
          )}
        >
          <img src={`${hostBE}/fe/image_project.png`} className="w-full h-[500px] rounded-[10px]" alt="image_rocky.png" />
        </div>
        {/* <div className="grid xl:grid-cols-1 gap-[23px] gap-y-[90px]"> */}
        <div className="">
          <div className="relative">
            <Swiper
              slidesPerView={width >= 1280 ? 3 : (width >= 768 ? 2 : (width >= 380 ? 1 : (width >= 330 ? 1 : 1)))}
              // slidesPerView={1}
              spaceBetween={30}
              // slidesPerGroup={1}
              // onRealIndexChange={(i)=> { setCurrentElement(i.activeIndex)}}
              loop={false}
              loopFillGroupWithBlank={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              navigation={{
                // Both prevEl & nextEl are null at render so this does not work
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              onSwiper={(swiper: any) => {
                // Delay execution for the refs to be defined
                setTimeout(() => {
                  // Override prevEl & nextEl now that refs are defined
                  swiper.params.navigation.prevEl = navigationPrevRef.current;
                  swiper.params.navigation.nextEl = navigationNextRef.current;

                  // Re-init navigation
                  swiper.navigation.destroy();
                  swiper.navigation.init();
                  swiper.navigation.update();
                });
              }}
              modules={[Navigation, Autoplay]}
              className="h-auto"
            >

              {
                categories.map((item, index) => {
                  return (
                    <SwiperSlide className="flex justify-center items-center" key={item.id}>
                      <Link className="w-full" to={`/sanpham/`}>
                        <div className="mx-auto w-full max-w-[320px] lg:max-w-none">
                          <div className="">
                            <p className={clsx("w-full text-px14 text-center lg:text-px32 line-clamp-1 mb-[35px] font-bold text-white-color cursor-pointer", { "animate__animated animate__flash": isInView })}> {translate.isEnglish ? item.nameVi : item.nameEn} </p>
                          </div>
                          <div className={clsx(
                            "w-full aspect-square rounded-[10px] border-2 border-solid border-white-color flex items-center justify-center cursor-pointer",
                            { "animate__animated animate__fadeInDown": isInView }
                          )} onClick={() => handleNavigate(item.id)}>
                            <img className="w-full h-full rounded-[10px]" src={`${item?.imageUrl}`} alt={item?.imageUrl ?? ""} />
                          </div>
                        </div>
                      </Link>
                    </SwiperSlide>
                  )
                })
              }
            </Swiper>
          </div>
        </div>
      </div>

    </div>
  );
}
