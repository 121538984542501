import { ROLE_ADMIN, ROLE_USER } from "../types";
import { hostBE } from "../types/host";

export const listLink = [
  {
    urlImage: `${hostBE}/fe/nav1.png`,
    path: "/quanly/taikhoan",
    role: ROLE_USER,
    content: "header.menu.menuManager.account",
  },
  {
    urlImage: `${hostBE}/fe/nav2.png`,
    path: "/quanly/trangchu",
    role: ROLE_USER,
    content: "header.menu.menuManager.homeManager",
  },
  {
    urlImage: `${hostBE}/fe/nav3.png`,
    path: "/quanly/sanpham",
    role: ROLE_USER,
    content: "header.menu.menuManager.productManager",
  },
  {
    urlImage: `${hostBE}/fe/projecmananger.png`,
    path: "/quanly/du_an",
    role: ROLE_USER,
    content: "header.menu.menuManager.Project",
  },
  // {
  //   urlImage: `${hostBE}/fe/nav4.png`,
  //   path: "/quanly/tuyendung",
  //   role: ROLE_USER,
  //   content: "header.menu.menuManager.recruitManager"
  // },
  // {
  //   urlImage: `${hostBE}/fe/CV_sidebar.png`,
  //   path: "/quanly/hosoungvien",
  //   role: ROLE_USER,
  //   content: "header.menu.menuManager.candidate",
  // },
  {
    urlImage: `${hostBE}/fe/nav5.png`,
    path: "/quanly/lienhe",
    role: ROLE_USER,
    content: "header.menu.menuManager.contactManager",
  },
  {
    urlImage: `${hostBE}/fe/nav6.png`,
    path: "/quanly/tuvan",
    role: ROLE_USER,
    content: "header.menu.menuManager.advise",
  },
  {
    urlImage: `${hostBE}/fe/nav7.png`,
    path: "/quanly/thuvien",
    role: ROLE_USER,

    content: "header.menu.menuManager.imageManager",
  },
  {
    urlImage: `${hostBE}/fe/nav8.png`,
    path: "/quanly/tintuc",
    role: ROLE_USER,
    content: "header.menu.menuManager.newManager",
  },
  {
    urlImage: `${hostBE}/fe/nav10.png`,
    path: "/quanly/thongtintaikhoan",
    role: ROLE_USER,
    content: "header.menu.menuManager.accountInfo",
  },
  // {
  //   urlImage: `${hostBE}/fe/nav12.png`,
  //   path: "/quanly/password",
  //   role: ROLE_USER,
  //   content: "header.menu.menuManager.changePassword",
  // },
  // {
  //   urlImage: `${hostBE}/fe/directorbiology.png`,
  //   path: "/quanly/direct_orbiography",
  //   content: "header.menu.menuManager.changePassword",
  // },
  {
    urlImage: `${hostBE}/fe/history_sidebar.png`,
    path: "/quanly/history",
    role: ROLE_USER,
    content: "header.menu.menuManager.historyDevelopment",
  },
  // {
  //   urlImage: `${hostBE}/fe/device.png`,
  //   path: "/quanly/device",
  //   role: ROLE_USER,
  //   content: "header.menu.menuManager.device",
  // },
];
