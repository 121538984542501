
import clsx from "clsx";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { getSupplier } from "../../reducers/supplier";
import { FormContact } from "../../types";
import { hostBE } from "../../types/host";
import ContactItem from "../ContactItem";

export default function MultiContact() {
  const dispatch = useAppDispatch();
  const supplierData = useAppSelector((state) => state.supplierSlice);

  useEffect(() => {
    dispatch(getSupplier({ page: 0, limit: 20 }));
  }, []);

  const handleLoop = (item: FormContact, index: any) => {
    for (let i = 0; i < index; i++) {
      if (item.social === supplierData.suppliers[i].social) return false;
    }

    return true;
  };

  // {"bg-[#EA4335]":type==="GMAIL", "bg-[#01ABEA]":type==="SKYPE", "bg-[#1A73E8]":type==="PHONE", "bg-[#1B86E4]":type==="ZALO", "bg-[#007ffe]":type==="FACEBOOK"}

  return (
    <div className="fixed top-[80%] translate-y-[-50%] sc>768:left-[24px] mb-[25px] md:right-[24px] z-[1]">
      <div className="mb-[30px]">
        {supplierData.suppliers.map((item, index) => {
          return (
            handleLoop(item, index) && (
              <div
                key={item.id}
                className="my-[25px] shadow-md cursor-pointer relative lssm:p-[10px] md:p-[15px] bg-white-color contact_parent w-auto h-auto box-shadow rounded-[100rem]"
                style={{
                  ["--color" as string]:
                    item.social === "GMAIL"
                      ? "#EA4335"
                      : item.social === "SKYPE"
                      ? "#01ABEA"
                      : item.social === "PHONE"
                      ? "1A73E8"
                      : item.social === "WHATSAPP"
                      ? "#25d366"
                      : item.social === "ZALO"
                      ? "#1B86E4"
                      : "#007ffe",
                }}
              >
                <img
                  className={clsx("lssm:w-[20px] md:w-[24px] object-cover")}
                  src={`${hostBE}/fe/${
                    item.social === "FACEBOOK"
                      ? "facebook_contact"
                      : item.social === "PHONE"
                      ? "phone_contact"
                      : item.social === "WHATSAPP"
                      ? "whatsapp_contact"
                      : item.social === "GMAIL"
                      ? "mail_contact"
                      : item.social === "SKYPE"
                      ? "sky_contact"
                      : "zalo_contact"
                  }.svg`}
                  alt=""
                />
                <div className="absolute top-[-75%] lssm:left-[50px] md:left-[-300px] contact_children sc>768:left-[-300px]">
                  {supplierData.suppliers.map((supplier) => {
                    return (
                      supplier.social === item.social && (
                        <ContactItem
                          key={supplier.id}
                          type={supplier.social}
                          url={supplier.avatarUrl}
                          name={supplier.fullname}
                          contact={supplier.link}
                        />
                      )
                    );
                  })}
                </div>
              </div>
            )
          );
        })}
      </div>
    </div>
  );
}
