import { useState } from "react";
import { useTranslation } from "react-i18next";
import SliderProject from "../../components/Home/SliderProject";
import TopicItem from "../../components/Home/Topic";
import TopicProduct from "../../components/Home/TopicProduct";
import { hostBE } from "../../types/host";

export default function Topic() {
  const [t] = useTranslation();

  return (
    <div>
      <div className="sc<992:my-[100px] sc991:my-[30px]">
        <TopicItem />
        <div>
          <div className="lssm:px-[24px] md:px-[80px]  xl:px-[50px]  w-1920:px-[162px] lssm:mt-[30px]  md:mt-[78px] ">
            <h2 className="text-[#11213F]  sc991:text-text-primary lssm:text-px20 md:text-[32px]  xl:text-[34px] 2xl:text-[48px]  sc991:text-center font-bold lssm:mb-[30px]  md:mb-[40px] pl-[20px] border-l-[25px] border-solid uppercase border-primary sc991:border-none block leading-[35px]">
              {t("home.topic.topic2")}
            </h2>
          </div>
          <div className="bg-product h-auto">
            <TopicProduct />
          </div>
        </div>

        <div className="lssm:px-[24px] md:px-[80px]  xl:px-[50px]  w-1920:px-[162px] lssm:mt-[30px]  md:mt-[78px]  ">
          <h2 className="text-[#11213F]  sc991:text-text-primary lssm:text-px20 md:text-[32px]  xl:text-[34px] 2xl:text-[48px]  sc991:text-center font-bold   mb-[40px] pl-[20px] border-l-[25px] border-solid uppercase border-primary sc991:border-none block leading-[35px]">
            {t("home.topic.topic3")}
          </h2>
          <SliderProject />
        </div>
      </div>
    </div>
  );
}
