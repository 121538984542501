import Cookies from "js-cookie";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineMenu } from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import { useAppDispatch, useAppSelector } from "../hooks/hook";
import { logout } from "../reducers/auth";
import { setTranslate } from "../reducers/translate";
import user, { logoutUser } from "../reducers/user";
import { navList } from "../Router/NavList";
import { BsSearch } from "react-icons/bs";
import Menu from "./Menu";
import MenuRows from "./MenuRows";
import clsx from "clsx";
import UserService from "../services/UserService";
import { hostBE } from "../types/host";

function Header() {
  const [isSearch, setSearch] = useState(false);
  const [searchHandle, setSearchHandle] = useState(true);
  const [inputValueRes, setInputValueRes] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>("");
  const languageLocals = Cookies.get("i18next") || "en";
  const [overlay, setOverlay] = useState<boolean>(false);
  const navRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLDivElement>(null);
  const imgSearchRef = useRef<HTMLDivElement>(null);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const auth = useAppSelector((state) => state.authSlice);
  const { currentUser } = useAppSelector((state) => state.userSlice);
  const translate = useAppSelector((state) => state.translateSlice);

  const location = useLocation();
  const disptach = useAppDispatch();

  const setImgageCountry = (value: string) => {
    if (value === "en") {
      i18n.changeLanguage("en");
      disptach(setTranslate("en"));
    } else {
      i18n.changeLanguage("vi");
      disptach(setTranslate("vi"));
    }
  };
  const handleSearch = () => {
    navigate("/search");
  };

  const handleChangeInput = (
    event: ChangeEvent<HTMLInputElement>,
    setValue: (value: any) => void
  ) => {
    const value = event.target.value;
    setValue(value);
  };

  const handleResSearch = () => {
    if (inputValueRes != "") {
      const formatKey = inputValueRes.replace(/ /g, "-");
      navigate(`/search?search=${formatKey}`);
      setInputValueRes("");
      setOverlay(false);
    }
  };

  const handleSearchKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = event.keyCode;
    const keySearch = event.target.value;
    if (keySearch != "" && keyCode === 13) {
      const formatKey = keySearch.replace(/ /g, "-");
      navigate(`/search?search=${formatKey}`);
      setSearch(false);
      setOverlay(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", (event) => {
      const windowTarger = event.target as HTMLElement;
      const tagetRef = navRef.current;
      const iconTarger = iconRef.current;
      const imageTaget = imgSearchRef.current;
      if (tagetRef && windowTarger && iconTarger && imageTaget) {
        const check = tagetRef?.contains(windowTarger);
        const checkIcon = iconTarger.contains(windowTarger);
        const checkImage = imageTaget.contains(windowTarger);
        if (!check && !checkIcon && !checkImage) {
          setOverlay(false);
        }
      }
    });
    return () => {
      window.removeEventListener("click", () => {});
    };
  }, [navRef.current]);

  useEffect(() => {
    const searchPath = location.pathname;
    if (searchPath === "/search") {
      setSearch(false);
      setSearchHandle(false);
    } else {
      setSearchHandle(true);
    }
  }, [location]);

  const logoutMethod = () => {
    disptach(logout());
    disptach(logoutUser());
  };

  return (
    <div className="lssm:h-[60px] md:h-[80px] xl:h-[100px] 2xl:h-[128px] header fixed top-0 bg-white z-[5] border-b border-b-border-box w-full w-1920:px-[162px] xl:px-[50px] lssm:px-[10px] sm:px-[30px]   flex items-center justify-between">
      {/* navleft */}

      <div ref={iconRef} className="xl:hidden inline-block">
        <AiOutlineMenu
          onClick={() => {
            setOverlay(!overlay);
          }}
          className="w-6 cursor-pointer text-primary"
        />
      </div>

      <Link
        to="/"
        className="logo xl:w-[140px] m992:w-[104px] w-[77px]"
        tabIndex={1}
      >
        <img
          src={`${hostBE}/fe/rockyplateau.jpg`}
          alt="ảnh"
          className="cursor-pointer"
        />
      </Link>

      {/* navcenter */}
      <div className="hidden xl:flex   items-center justify-center xl:w-4/5 w-7/12">
        <MenuRows />
      </div>

      {/* navright */}
      <div className="flex items-center justify-end  relative w-max">
        <div className="sm:flex items-center justify-end relative">
          {isSearch && (
            <input
              onKeyUp={(event) => {
                handleSearchKeyUp(event);
              }}
              type="text"
              placeholder="Tìm kiếm ..."
              className="xl:block hidden absolute top-[400%] right-[200%] z-30 w-[300px] 2xl:w-[400px] 2xl:py-2 2xl:px-6 xl:py-[4px] py-2 px-6 text-base  rounded-[30px] border border-solid border-primary focus:outline-none"
            />
          )}
          {searchHandle && (
            <BsSearch
              tabIndex={navList.length + 2}
              onClick={handleSearch}
              className="min-h-[24px] focus:outline-none text-2xl text-primary xl:block hidden ml-3 cursor-pointer"
            />
          )}
          {searchHandle && (
            <div ref={imgSearchRef}>
              <BsSearch
                onClick={handleSearch}
                className="min-h-5 text-primary text-2xl w-5 xl:hidden cursor-pointer"
                tabIndex={navList.length + 2}
              />
            </div>
          )}
        </div>
        <div
          className={clsx(
            "relative items-center m992:flex",
            searchHandle ? "hidden px-4" : "flex px-0 m992:px-4"
          )}
          tabIndex={navList.length + 3}
        >
          <label
            htmlFor="translate"
            className={clsx(
              " w-[30px] mr-[12px] rounded-[2px] overflow-hidden h-[20px] z-20 text-center border-solid border-primary flex justify-center items-center",
              { border: translate.isEnglish }
            )}
          >
            <img
              src={`${hostBE}/fe/vietnamlng.png`}
              onClick={() => setImgageCountry("vi")}
              alt="vi"
              className=" cursor-pointer w-full h-full"
            />
          </label>
          <label
            htmlFor="translate"
            className={clsx(
              "w-[30px] h-[20px] rounded-[2px] overflow-hidden  z-20 text-center border-solid border-primary flex justify-center items-center",
              { border: !translate.isEnglish }
            )}
          >
            <img
              onClick={() => setImgageCountry("en")}
              src={`${hostBE}/fe/enlng.png`}
              alt="englng"
              className=" cursor-pointer w-full h-full"
            />
          </label>
        </div>
        <div tabIndex={navList.length + 4}>
          {currentUser ? (
            <Button
              color="empty"
              onClick={logoutMethod}
              className="lssm:w-max 2xl:w-[130px] md:h-[32px] 2xl:h-[50px]  m992:block hidden lssm:text-px13 text-text-primary 2xl:text-base rounded-[30px]"
            >
              {t("button.logout")}
            </Button>
          ) : (
            <Button
              color="primary"
              onClick={() => UserService.doLogin()}
              className="lssm:w-max 2xl:w-[130px] md:h-[32px] 2xl:h-[50px] m992:block hidden lssm:text-px13 text-white 2xl:text-base rounded-[30px]"
            >
              {t("button.login")}
            </Button>
          )}
        </div>
      </div>

      {overlay && (
        <div className="overlay-nav xl:hidden absolute w-full h-screen pb-[120px] z-50  left-0 top-full flex justify-start">
          <div
            ref={navRef}
            className="h-full scrollbar overflow-y-scroll w-[500px] max-w-[98%] items-center justify-center bg-white border-t border-t-border-box
        "
          >
            {searchHandle && (
              <div className="flex items-center justify-between w-full py-3 ">
                <div className="w-[80%] relative pl-4">
                  <input
                    onChange={(event) => {
                      handleChangeInput(event, setInputValueRes);
                    }}
                    onKeyUp={(event) => {
                      handleSearchKeyUp(event);
                    }}
                    className="w-ful py-1 pl-6 pr-8 text-base w-full rounded-[30px] border border-solid border-primary focus:outline-none"
                    type="text"
                    placeholder={
                      translate.isEnglish ? "Tìm kiếm ..." : "Search"
                    }
                  />
                  <BsSearch
                    onClick={handleResSearch}
                    className="w-4 h-4 cursor-pointer text-primary absolute top-[50%] sm-390:right-4 right-3 translate-y-[-50%] "
                  />
                </div>

                <div className="relative inline-block px-4 language_image tootip m992:hidden ">
                  <label
                    htmlFor="translate"
                    className={clsx(
                      " w-[30px] mr-[12px] rounded-[2px] overflow-hidden  mb-2 h-[20px] z-[99999] text-center border-solid border-primary flex justify-center items-center",
                      { border: translate.isEnglish }
                    )}
                  >
                    <img
                      src={`${hostBE}/fe/vietnamlng.png`}
                      onClick={() => setImgageCountry("vi")}
                      alt="vi"
                      className=" cursor-pointer w-full h-full"
                    />
                  </label>
                  <label
                    htmlFor="translate"
                    className={clsx(
                      "w-[30px] h-[20px] rounded-[2px] overflow-hidden  z-[99999] text-center border-solid border-primary flex justify-center items-center",
                      { border: !translate.isEnglish }
                    )}
                  >
                    <img
                      onClick={() => setImgageCountry("en")}
                      src={`${hostBE}/fe/enlng.png`}
                      alt="englng"
                      className=" cursor-pointer w-full h-full"
                    />
                  </label>
                </div>
              </div>
            )}
            <div className="m992:hidden flex items-center px-3 py-3 border-b  border-b-border-box">
              <img
                src={
                  currentUser && currentUser.avatarUrl
                    ? currentUser.avatarUrl
                    : `${hostBE}/fe/loginImage.png`
                }
                alt=""
                className="w-10 h-10 mr-4 rounded-[50%]"
              />
              {currentUser ? (
                <Button
                  color="empty"
                  onClick={logoutMethod}
                  className="cursor-pointer md:text-px13 sm-480:text-base text-xs"
                >
                  {t("button.logout")}
                  {/* Đăng xuất */}
                </Button>
              ) : (
                <button
                  onClick={() => UserService.doLogin()}
                  className="cursor-pointer md:text-lg sm-480:text-base text-xs"
                >
                  {t("button.login")}
                  {/* Đăng nhập */}
                </button>
              )}
            </div>
            <Menu navOnclick={() => setOverlay(false)} />
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
