import clsx from "clsx";
import { t } from "i18next";
import React, { ChangeEvent, useEffect, useState, useTransition } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import {
  addCategory,
  deleteCategory,
  editCategory,
  getCategory,
} from "../../reducers/categorySlice";
import { hideModal, showModal } from "../../reducers/modal";
import { pushPopup } from "../../reducers/popupSlice";
import uploadService from "../../services/uploadImage";
import { categoryType } from "../../typeProps/Productype";
import { hostBE } from "../../types/host";
import ButtonAddImage from "../Button/ButtonAddImage";
import DialogConfirm from "../DialogConfirm";

function CategoryModal() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isEnglish } = useAppSelector((state) => state.translateSlice);
  const { categoryList, error, loadding } = useAppSelector(
    (state) => state.categorySlice
  );
  const navigator = useNavigate();
  const [inputForm, setInputForm] = useState<any>({
    id: null,
    nameVi: "",
    nameEn: "",
    descriptionEn: "",
    descriptionVi: "",
    imagePath: null,
    imageUrl: null,
  });
  const [inputError, setInputError] = useState({
    nameEn: false,
    nameVi: false,
    image: false,
    descriptionEn: false,
    descriptionVi: false,
  });
  const [fileImage, setFileImage] = useState<any>(null);
  const handleDelete = (id: number) => {
    dispatch(
      showModal(
        <DialogConfirm
          message="bạn có muốn xóa?"
          onClick={() => {
            dispatch(deleteCategory(id));
          }}
        />
      )
    );
    setInputForm({
      id: null,
      nameEn: "",
      nameVi: "",
      descriptionEn: "",
      descriptionVi: "",
      imagePath: null,
      imageUrl: null,
    });
  };
  const handleAddCategory = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;
    setInputError({
      ...inputError,
      [name]: false,
    });
    setInputForm({
      ...inputForm,
      [name]: value,
    });
  };
  const handleTextArialInput = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputError({
      ...inputError,
      [name]: false,
    });
    setInputForm({
      ...inputForm,
      [name]: value,
    });
  };
  const handleUpdate = (item: categoryType) => {
    setInputForm({
      id: item.id,
      nameEn: item.nameEn,
      nameVi: item.nameVi,
      descriptionEn: item.descriptionEn,
      descriptionVi: item.descriptionVi,
      imagePath: item.imagePath,
      imageUrl: item.imageUrl,
    });
    setInputError({
      nameEn: false,
      nameVi: false,
      image: false,
      descriptionEn: false,
      descriptionVi: false,
    });
  };
  const handleChoseFile = (file: any) => {
    const toBase64 = (file: any) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    if (file) {
      setFileImage(file);
      setInputError({
        ...inputError,
        image: false,
      });
      toBase64(file).then((result) => {
        setInputForm({
          ...inputForm,
          imageUrl: result,
        });
      });
    }
  };
  const handleSubmit = async () => {
    if (
      inputForm.nameEn == "" &&
      inputForm.nameVi == "" &&
      inputForm.descriptionEn == "" &&
      inputForm.descriptionVi == "" &&
      inputForm.imageUrl == null
    ) {
      dispatch(
        pushPopup({
          type: "WARNING",
          message: isEnglish
            ? "Vui lòng nhập thông tin."
            : "Please enter information",
        })
      );
      setInputError({
        nameVi: true,
        nameEn: true,
        image: true,
        descriptionEn: true,
        descriptionVi: true,
      });
      dispatch(hideModal());
    } else if (inputForm.nameEn == "") {
      setInputError({
        ...inputError,
        nameEn: true,
      });
      dispatch(
        pushPopup({
          type: "WARNING",
          message: isEnglish
            ? "Vui lòng nhập trường tiếng anh."
            : "Please enter the English field.",
        })
      );
    } else if (inputForm.nameVi == "") {
      setInputError({
        ...inputError,
        nameVi: true,
      });
      dispatch(
        pushPopup({
          type: "WARNING",
          message: isEnglish
            ? "Vui lòng nhập trường tiếng việt."
            : "Please enter the Vietnamese field.",
        })
      );
    } else if (inputForm.descriptionEn == "") {
      setInputError({
        ...inputError,
        descriptionEn: true,
      });
      dispatch(
        pushPopup({
          type: "WARNING",
          message: isEnglish
            ? "Vui lòng nhập chút thích tiếng Anh."
            : "Please enter English captions.",
        })
      );
    } else if (inputForm.descriptionVi == "") {
      setInputError({
        ...inputError,
        descriptionVi: true,
      });
      dispatch(
        pushPopup({
          type: "WARNING",
          message: isEnglish
            ? "Vui lòng nhập chút thích tiếng Việt."
            : "Please enter VietNamese captions.",
        })
      );
    } else {
      if (inputForm.id) {
        if (fileImage) {
          const data = new FormData();
          data.append("file", fileImage);
          const fileResult = await uploadService.upload(data);
          if (fileResult) {
            const newInputform = {
              ...inputForm,
              imageUrl: fileResult.list[0].image_url,
              imagePath: fileResult.list[0].image_path,
            };
            dispatch(editCategory(newInputform));
          }
        } else {
          dispatch(editCategory(inputForm));
        }
      } else {
        if (!fileImage) {
          setInputError({
            ...inputError,
            image: true,
          });
          dispatch(
            pushPopup({
              type: "WARNING",
              message: isEnglish
                ? "Bạn chưa chọn ảnh."
                : "You have not selected a photo.",
            })
          );
          return;
        } else {
          const data = new FormData();
          data.append("file", fileImage);
          const fileResult = await uploadService.upload(data);
          if (fileResult) {
            dispatch(
              addCategory({
                nameEn: inputForm.nameEn,
                nameVi: inputForm.nameVi,
                descriptionVi: inputForm.descriptionVi,
                descriptionEn: inputForm.descriptionEn,
                imageUrl: fileResult.list[0].image_url,
                imagePath: fileResult.list[0].image_path,
              })
            );
          } else {
            pushPopup({
              type: "WARNING",
              message: isEnglish
                ? "Không upload được ảnh"
                : "Can't upload pictures",
            });
          }
        }
      }
      setInputForm({
        id: null,
        nameEn: "",
        nameVi: "",
        descriptionVi: "",
        descriptionEn: "",
      });
      setFileImage(null);
    }
  };
  useEffect(() => {
    dispatch(getCategory());
    return () => {};
  }, []);

  return (
    <div className="w-full rounded-md bg-white py-[50px] px-4">
      <div className="w-full">
        <p className="sm-480:text-px32 font-bold text-xl sm:mb-[50px] mb-4 text-center lg:text-black text-bg_blue_bold">
          {t("categoryForm.title")}
        </p>
        <p className="sm-480:text-base text-sm font-bold text-bg_blue_bold py-4 text-left">
          {t("categoryForm.labelList")}
        </p>
        <p className="mb-5 sm-480:text-base text-sm text-primary font-normal">
          {t("categoryForm.lable_description")}
        </p>

        <div className="w-full min-h-[200px] flex flex-wrap items-start mb-[10px] py-4 pl-4 pr-10 border border-bg_blue_bold rounded-md relative">
          {error ? (
            <p className="mb-5 text-base underline decoration-1 text-red-700 font-normal">
              {error}
            </p>
          ) : (
            <>
              {categoryList.length > 0 &&
                categoryList.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className="w-fit flex bg-bg_blue_bold items-center cursor-pointer justify-center py-3 sm-390:px-5 px-3 mb-[10px]  rounded-[50px] bg-text-bg_blue_bold mr-[10px]">
                        <p
                          onClick={() => {
                            handleUpdate(item);
                          }}
                          className="mr-[14px] text-white sm-480:text-base text-sm font-normal"
                        >
                          {isEnglish ? item.nameVi : item.nameEn}
                        </p>
                        <img
                          onClick={() => handleDelete(item.id)}
                          src={`${hostBE}/fe/iconcancel.png`}
                          alt="cancel"
                          className="w-[18px] h-[18px] cursor-pointer"
                        />
                      </div>
                    </div>
                  );
                })}
            </>
          )}
        </div>
        {/* thên danh mục dịch vụ */}
        <p className="mb-5 sm-480:text-base text-sm  decoration-1 font-bold text-bg_blue_bold">
          {inputForm?.id
            ? t("categoryForm.lable_edit")
            : t("categoryForm.lable_add")}
        </p>

        <div className="w-full flex flex-wrap justify-between mb-8">
          <div className="w-[48%] flex flex-col justify-between">
            <label
              className="sm-480:text-base text-sm decoration-1text-primary font-normal"
              htmlFor="categoryVi"
            >
              {t("categoryForm.lableEn")}
            </label>
            <input
              name="nameVi"
              value={inputForm.nameVi}
              id="categoryVi"
              className={clsx(
                "w-full mt-3 border sm-480:text-base text-sm  py-3 focus:outline-none px-5 rounded-md",
                inputError.nameVi ? "border-border-red" : "border-bg_blue_bold"
              )}
              type="text"
              onChange={(event) => handleAddCategory(event)}
            />
          </div>
          <div className="w-[48%] flex flex-col justify-between">
            <label
              className="sm-480:text-base text-sm decoration-1text-primary font-normal"
              htmlFor="categoryEn"
            >
              {t("categoryForm.lableVi")}
            </label>
            <input
              id="categoryEn"
              className={clsx(
                "w-full mt-3 border sm-480:text-base text-sm py-3 focus:outline-none px-5 rounded-md",
                inputError.nameEn ? "border-border-red" : "border-bg_blue_bold"
              )}
              name="nameEn"
              value={inputForm.nameEn}
              type="text"
              onChange={(event) => handleAddCategory(event)}
            />
          </div>
        </div>

        <div className="w-full">
          <label
            htmlFor="descriptVi"
            className="sm-480:text-base text-sm  decoration-1 font-bold text-bg_blue_bold"
          >
            {t("categoryForm.descriptionVi")}
          </label>
          <textarea
            id="descriptVi"
            name="descriptionVi"
            className={clsx(
              "w-full mt-2 mb-8 border sm-480:text-base text-sm  py-3 focus:outline-none px-5 rounded-md",
              inputError.descriptionVi
                ? "border-border-red"
                : "border-bg_blue_bold"
            )}
            value={inputForm.descriptionVi}
            onChange={(event) => handleTextArialInput(event)}
            rows={4}
            cols={50}
          ></textarea>

          <label
            htmlFor="descriptEn"
            className="sm-480:text-base text-sm  decoration-1 font-bold text-bg_blue_bold"
          >
            {t("categoryForm.descriptionEn")}
          </label>
          <textarea
            id="descriptEn"
            name="descriptionEn"
            className={clsx(
              "w-full mt-2 mb-11 border sm-480:text-base text-sm  py-3 focus:outline-none px-5 rounded-md",
              inputError.descriptionEn
                ? "border-border-red"
                : "border-bg_blue_bold"
            )}
            rows={4}
            cols={50}
            value={inputForm.descriptionEn}
            onChange={(event) => handleTextArialInput(event)}
          ></textarea>
        </div>

        <div
          className={clsx(
            "py-7 xl:px-14 sm-480:px-8 px-4 rounded-md border flex  sm:flex-row m992:justify-start sm:justify-between items-center flex-col-reverse",
            inputError.image ? "border-border-red" : "border-bg_blue_bold"
          )}
        >
          <div className="flex  m992:w-[53%] md:h-full sm:w-[48%] sc<992:flex-col sm:mt-0 mt-8">
            <p className="lssm:text-px14 md:text-px16 font-medium md:mt-[-35px]  text-text-medium sc991:hidden">
              {t("dashboard.news.create.form.upload_format")}
            </p>
            <ButtonAddImage
              className="sc<992:mt-[50px] sc991:w-full cursor-pointer"
              onClickButton={handleChoseFile}
            />
          </div>

          <div className="flex xl:w-[35%] flex-col sc991:mt-[26px] m992:w-[35%] sm:w-[48%] w-full">
            <p className="lssm:text-px14 md:text-px16 font-bold text-primary mb-3">
              {t("form.image_cate")}
            </p>
            <div className="2xl:h-[200px] sm-480:h-[160px] h-[130px] flex items-center justify-center bg-bg-gray rounded-md ">
              {inputForm.imageUrl ? (
                <img
                  className="w-full h-full rounded-md"
                  src={inputForm.imageUrl}
                  alt="imagedefault"
                />
              ) : (
                <img src={`${hostBE}/fe/imagedefault.png`} alt="imagedefault" />
              )}
            </div>
          </div>
        </div>

        <div className="flex justify-end mt-[100px]">
          <button
            onClick={() => navigator("/quanly/sanpham")}
            className="2xl:py-[18px] py-[14px] mr-3 2xl:w-[180px] sm-390:w-[170px] w-[100px] border rounded-md border-text-lightred text-text-lightred font-medium sm-480:text-base text-sm text-center"
          >
            {t("button.button_prev")}
          </button>
          <button
            onClick={handleSubmit}
            className="2xl:py-[18px] py-[14px] inline-block font-medium 2xl:w-[180px] sm-390:w-[170px] w-[100px] border rounded-md bg-bg_blue_bold text-white sm-480:text-base text-sm text-center"
          >
            {t("button.button_save")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default CategoryModal;
