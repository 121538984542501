import React from "react";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import App from "./App";
import i18n from "./i18nConfig";
import reportWebVitals from "./reportWebVitals";
import "./styles/index.css";
import {store} from "./configs/redux/store";
import { Provider } from "react-redux";
import HttpService from "./configs/api";
import UserService from "./services/UserService";
import ModalProvider from "./context/contextModal";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const onAuthenticatedCallback = () => {
  root.render(
    <I18nextProvider i18n={i18n}>
        <Provider store={store} >
          <ModalProvider >
            <App />
          </ModalProvider>
        </Provider>
      </I18nextProvider>
  );
  
}

UserService.initKeycloak(onAuthenticatedCallback);
HttpService.configure();

reportWebVitals();
