import { createContext, ReactNode, useRef, useState } from "react";

export const ModalContext = createContext<any>(null);

type Props = {
  children?: ReactNode;
};

const ModalProvider: React.FC<Props> = ({ children }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [contentModal, setContentModal] = useState<JSX.Element>(<></>);
  
  let backdropRef = useRef();
  const closeModal = () => {
    setContentModal(<></>);
    setShowModal(false);
  };

  const valueContext = {
    showModal,
    setShowModal,
    contentModal,
    setContentModal,
    closeModal,
    backdropRef,
  };
  return (
    <ModalContext.Provider value={valueContext}>
      <div className="relative">
       <div className="absolute top-[10%] left-[50%] translate-x-[-50%] z-20">
        {contentModal}
       </div>
      {children}
      </div>
    </ModalContext.Provider>
  );
};

export default ModalProvider;
