import clsx from "clsx";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ValueCoreItem from "../../components/About/ValueCoreItem";
import useInView from "../../hooks/useInView";
import { hostBE } from "../../types/host";




export default function Vision() {

    const [t] = useTranslation();
    const [dataCore] = useState([
        {
            "titleVi": "Tin cậy",
            "titleEn": "Trust",
            "descriptionVi": "Chúng tôi rất trân trọng niềm tin của khách hàng, luôn lắng nghe và đáp ứng nhu cầu của khách hàng. GLC mong rằng công ty sẽ trở thành người đồng hành đáng tin cậy của các nhà đầu tư.",
            "descriptionEn": "We value our customer’s trust and always listen to meet their needs. GLC hopes to establish itself as a trustworthy partner for investors."
          },
    
         {
            "titleVi": "Hứa hẹn",
            "titleEn": "Promise",
            "descriptionVi": "GLC luôn tập trung phát triển bản thân, trở thành công ty đầy hứa hẹn với những nhà cung cấp, đầu tư.",
            "descriptionEn": "GLC is always focused on self-development in order to become a promising company with suppliers and investors."
          },
    
          {
            "titleEn": "Healthy competition",
            "titleVi": "Cạnh tranh lành mạnh",
            "descriptionVi": "Chúng tôi luôn coi những công ty cùng lĩnh vực là những người đồng hành trong chặng đường phát triển. Chúng tôi học hỏi từ họ và luôn cạnh tranh lành mạnh bằng thế mạnh của mình.",
            "descriptionEn": "We always consider companies in the same industry to be development partners. We learn from them and always compete to our strengths in a healthy manner."

          },
    
          {
            "titleVi": "Thân thiện",
            "titleEn": "Friendly",
            "descriptionVi": "GLC luôn chú trọng trong việc bảo vệ môi trường. Chúng tôi trồng nhiều cây xanh với mô hình công viên trong nhà máy. Ngoài ra chúng tôi cũng tích cực tham gia các hoạt động từ thiện để tạo ảnh hưởng tích cực tới cộng đồng.",
            "descriptionEn": "GLC has always prioritized environmental preservation. In the factory, we plant many trees inside our factory. Furthermore, we actively participate in charitable activities to leave a positive impact on the community.",
          },
            {
            "titleVi": "Bình đẳng, đoàn kết, cùng phát triển",
            "titleEn": "Equality, solidarity, mutual development",
            "descriptionEn": "Every employee of the company plays an important role in the growth of GLC. We always treat our employees fairly and respect their values and abilities.",
            "descriptionVi": "Mỗi nhân viên của công ty đều là nhân tố quan trọng góp phần xây dựng phát triển GLC. Chúng tôi luôn đối xử bình đẳng với các nhân viên của mình, coi trọng giá trị và năng lực của họ.",
          }

    ])
    const topRef = useInView();


    return <div>
            <div>
                <h2 className="uppercase lssm:text-px20 md:text-[40px] xl:text-[48px] text-text-title font-bold lssm:my-[50px] md:my-[132px] text-center animate__animated  animate__fadeInUp">{t('about.vision.visionandmision')}</h2>
                <div className="grid lssm:grid-cols-1 md:grid-cols-2 lssm:gap-[100px] md:gap-[177px]" ref={topRef.ref}>
                    <div className="flex lssm:flex-col lsm-380:flex-row sm:flex-col 2xl:flex-row">
                        <div className={clsx("lssm:mr-[32px] 2xl:mr-[50px] flex justify-center", {"animate__animated animate__fadeInLeft animate__delay-0.5s":topRef.isInView})}>
                            <img src={`${hostBE}/fe/vision_image1.png`} className="lssm:min-w-[250px] lsm-380:min-w-[150px] sm:min-w-[200px] lssm:h-auto lssm:object-contain md:object-contain 2xl:min-w-[300px] md:h-auto " alt="" />
                        </div>

                        <div className={clsx({"animate__animated animate__fadeInUp animate__delay-0.5s": topRef.isInView})}>
                            <h4 className="lssm:text-px16 sm:text-[28px] md:text-[32px] font-bold text-text-primary lssm:mt-[24px] 2xl:mt-[8px]  sm:text-center 2xl:flex-left">{t('about.vision.vision')}</h4>
                            <p className="text-justify lssm:text-px14 md:text-px16  md:mt-[12px] 2xl:mt-[40px]">
                            {t('about.vision.vision_decription')}
                            </p>
                        </div>
                    </div>

                    <div className="flex lssm:flex-col lsm-380:flex-row sm:flex-col 2xl:flex-row">
                        <div className={clsx("lssm:mr-[32px] 2xl:mr-[50px] flex justify-center", {"animate__animated animate__fadeInLeft animate__delay-0.5s":topRef.isInView})}>
                            <img src={`${hostBE}/fe/vision_image2.png`} className="lssm:min-w-[250px] lsm-380:min-w-[150px] sm:min-w-[200px] lssm:h-auto lssm:object-contain md:object-contain 2xl:min-w-[300px] md:h-auto " alt="" />
                        </div>

                        <div  className={clsx({"animate__animated animate__fadeInUp animate__delay-0.5s": topRef.isInView})}>
                            <h4 className="lssm:text-px16 sm:text-[28px] md:text-[32px] font-bold text-text-primary lssm:mt-[24px] 2xl:mt-[8px]  sm:text-center 2xl:flex-left">{t('about.vision.mission')}</h4>
                            <p className="text-justify lssm:text-px14 md:text-px16  md:mt-[12px] 2xl:mt-[40px]">
                                  {t("about.vision.mission_description")}
                            </p>

                        </div>

                    </div>
                </div>
            </div>

            <div className="mb-[100px]">
            <h2 className="uppercase lssm:text-px20 md:text-[40px]  text-text-title xl:text-[48px] font-bold lssm:my-[50px] md:my-[132px] text-center" >{t('about.vision.value')}</h2>
                    <div className="flex flex-col">
                        {
                            dataCore.map((value, index) => {
                                return  <ValueCoreItem key={index} value={value} isOver={dataCore.length - 1 === index} index={index} />
                            })
                        }
                    </div>


            </div>

        
        
        
        </div>
}