import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlinePlus } from "react-icons/ai"
import {FiEdit} from "react-icons/fi"
import Button from "../../components/Button"
import DialogConfirm from "../../components/DialogConfirm";
import ModalContact from "../../containers/Dashboard/Contact/ModalContact";
import { useAppDispatch, useAppSelector } from "../../hooks/hook"
import { showModal } from "../../reducers/modal";
import { deleteSupplier, getSupplier } from "../../reducers/supplier";
import { FormContact } from "../../types";
import { hostBE } from "../../types/host";

export default function FormContactItem() {

    const dispatch = useAppDispatch();
    const supplier = useAppSelector(state => state.supplierSlice);
    const [t] = useTranslation()

    useEffect(()=> {
        dispatch(getSupplier({
            page: 0,
            limit: 20
        }))
    }, [])
    
    const showModalContact = () => {
        dispatch(showModal(<ModalContact type="ADD" />))
    }

    const showEdit = (item:FormContact) => {
        dispatch(showModal(<ModalContact type="EDIT" data={item} />))
    }

    const showModalConfirm = (id:string) => {
        dispatch(showModal(<DialogConfirm message="Xác nhận xóa thông tin liên hệ khỏi hệ thống ?" onClick={()=>deleteSupplierMethod(id)}  />))
    }

    const deleteSupplierMethod = (id:string) => {
        dispatch(deleteSupplier(parseInt(id)))
    }

    return (
        <div className="flex flex-col mb-[130px]">
            <h2 className="font-bold text-text-primary text-center  mt-[74px] lssm:mb-[32px] md:mb-[107px] lssm:text-px20 md:text-[40px] 2xl:text-[48px]">{t("dashboard.contact.title")}</h2>
           
           
            <div className="grid lssm:grid-cols-1 xl:grid-cols-2 gap-x-5 lssm:gap-y-[30px] md:gap-y-10 sc>768:order-3">
                {
                    
                    supplier.suppliers.map((item) => {
                        return (
                                <div key={item.id} className=" bg-white-color contact-item w-full flex items-center  py-[40px] px-[29px]">
                                    <div className="flex justify-center rounded-[100rem] bg-[#DBEAFF] items-center lssm:w-[40px] lssm:h-[40px] md:w-[80px] md:h-[80px]">
                                        <img
                                         src={`${hostBE}/fe/${item.social === 'PHONE' ? 'phone_contact' : item.social === "GMAIL" ? "mail_contact" : item.social === "SKYPE" ? 'sky_contact' : item.social === "FACEBOOK" ? "facebook_contact" : item.social === 'WHATSAPP' ? 'whatsapp_contact' : "zalo_contact"}.svg`}
                                          alt=""
                                            className="sc>768:w-[20px]"
                                          />
                                    </div>
                                    <div className="lssm:ml-[18px]  md:ml-[32px] w-[60%]">
                                        <p className="sc>768:text-px14 md:text-px16 2xl:text-px20  font-bold text-text-decoration">{item.fullname}</p>
                                        <p className="lssm:text-px14 md:text-px16  text-[#979797] break-words">{item.link}</p>
                                    </div>
                                    <div className="flex justify-end">
                                        <span onClick={()=> showEdit(item)} className="text-text-primary text-px20 cursor-pointer w-[40px] h-[40px] flex items-center justify-center rounded-[50%] hover:bg-[#DBEAFF]"><FiEdit /></span>
                                        <span onClick={()=> showModalConfirm(item.id+"")} className="text-text-primary ml-2 cursor-pointer w-[40px] h-[40px] flex items-center justify-center rounded-[50%] hover:bg-[#DBEAFF]"><img src={`${hostBE}/fe/delete_icon.png`} alt="" /></span>
                                    </div>
                                </div>

                        )
                    })
                }        
                
            </div>
            <div className="flex justify-center sc>768:mb-[32px] md:mt-[90px] w-full">
                <Button onClick={showModalContact} color="primary" className="text-white-color sc>768:text-px14 h-[60px] lssm:w-full md:w-auto sc>768:order-2">
                    <div className="flex justify-center items-center"><span className="mr-3 block"><AiOutlinePlus /></span>{t("dashboard.contact.btn_new")}</div>
                </Button>
            </div>
       </div>
    )
}